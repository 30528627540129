import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-date",
  template: `
<mat-form-field class="demo-full-width margin-top" [formGroup]="group">
  <input matInput
    id="{{field.name}}"
    class="form-control lead-stage-date"
    [formControlName]='field.name'
    [min]="min"
    [max]="max"
    [owlDateTime]="dt6"
    [owlDateTimeTrigger]="dt6"
    [placeholder]='field.label'
    autocomplete="random"
    readonly
  />
  <owl-date-time [pickerType]="'calendar'" #dt6></owl-date-time>
  <mat-hint></mat-hint>
  <mat-error *ngIf="group.get(field.name).hasError('required')">{{field.label}} is required</mat-error>
  <mat-error *ngIf="group.get(field.name).hasError('owlDateTimeMax')"
        >{{ field.label }} exceeds the maximum value of {{datepipe.transform(group.get(field.name).errors.owlDateTimeMax['max'], 'MM-dd-yyyy')}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('owlDateTimeMin')"
      >{{ field.label }} is less than the minimum value of {{datepipe.transform(group.get(field.name).errors.owlDateTimeMin['min'], 'MM-dd-yyyy')}}</mat-error
    >
</mat-form-field>
<br/>
`,
  styles: []
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;
  
  min: any = '';
  max: any = '';

  constructor(private datepipe: DatePipe) {}
  ngOnInit() {
    console.log('date field value at onInit');
    console.log(this.field.value);

    console.log('extra validation found in date field');
    console.log(this.validation);

    this.validation.forEach(val => {
      if(val['start_date'] && val['start_date'].length != 0) {
        console.log('printing start date');
        this.min = new Date(val['start_date']);
        console.log(this.min);
      }
      if(val['end_date'] && val['end_date'].length != 0) {
        console.log('printing end date');
        this.max = new Date(val['end_date']);
        console.log(this.max);
      }
    });
  }
}
