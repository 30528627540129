import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./core";
import { AuthLayoutComponent } from "./core";
import { LoginComponent } from "./core/account-management/login/login.component";
import { LeadNoPropertyComponent } from "./core/lead-management/lead-no-property/lead-no-property.component";
import { LeadListViewComponent } from "./core/lead-management/lead-list-view/lead-list-view.component";
import { UserManagementComponent } from "./core/account-management/user-management/user-management.component";
import { ClusterManagementComponent } from "./core/cluster-management/cluster-management/cluster-management.component";
import { ClusterDepartmentManagementComponent } from "./core/cluster-management/cluster-department-management/cluster-department-management.component";
import { ClusterAdminManagementComponent } from "./core/cluster-management/cluster-admin-management/cluster-admin-management.component";
import { PropertyClusterAssignmentComponent } from "./core/lead-management/property-cluster-assignment/property-cluster-assignment.component";
import { LoginGuard } from "./common/service/login-guard/login.guard";
import { AssociateManagementComponent } from "./core/associate-management/associate-management/associate-management.component";
import { AuthGuard } from "./common/service/auth-guard/auth.guard";
import { Error4Component } from "./common/component/error/error4/error4.component";
import { StageViewComponent } from "./core/lead-management/stage-view/stage-view.component";
// import { PartnerManagementComponent } from "./core/partner-management/partner-management/partner-management.component";
import { CreateStageComponent } from "./core/cluster-workflow/create-stage/create-stage.component";
import { CreateStageFieldComponent } from "./core/cluster-workflow/create-stage-field/create-stage-field.component";
import { CreateStageLinkComponent } from "./core/cluster-workflow/create-stage-link/create-stage-link.component";
import { MapViewComponent } from "./core/lead-management/map-view/map-view.component";
import { DashboardComponent } from "./core/lead-management/dashboard/dashboard.component";
import { IotConfigurationComponent } from "./core/lead-management/iot-configuration/iot-configuration.component";
import { DepartmentStageAccessComponent } from "./core/cluster-workflow/department-stage-access/department-stage-access.component";
import { PartnerLinkComponent } from "./core/partner-management/partner-link/partner-link.component";
import { LeadPerformanceComponent } from "./core/performance-reporting/lead-performance/lead-performance.component";
import { ClusterGenerationComponent } from "./core/performance-reporting/cluster-generation/cluster-generation.component";
import { DepartmentOrganisationComponent } from "./core/people-management/department-organisation/department-organisation/department-organisation.component";
import { PartnerOrganisationComponent } from "./core/partner-management/partner-org/partner-organisation/partner-organisation.component";
import { PropertyGenerationComponent } from "./core/performance-reporting/property-generation/property-generation/property-generation.component";
import { LeadReportComponent } from "./core/performance-reporting/lead-report/lead-report/lead-report.component";
import { WorkflowManagementComponent } from './core/cluster-workflow/workflow-management/workflow-management.component';
import { ActivityLogListComponent } from "./core/lead-management/activity-log-list/activity-log-list.component";
import { ActivityDashboardComponent } from './core/performance-reporting/activity-dashboard/activity-dashboard.component';
import { EPCPartnerOrganisationComponent } from './core/partner-management/partner-org/epc-organisation/partner-organisation.component';
import { AppDownloadComponent } from './common/component/download-page/app-download/app-download.component';
import { StageAnalyticsComponent } from "./core/performance-reporting/stage-analytics/stage-analytics.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        canActivate: [LoginGuard]
      },
      {
        path: "apps",
        component: AppDownloadComponent,
      },
      {
        path: "error",
        component: Error4Component
      }
    ]
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "lead/list-view",
        component: LeadListViewComponent,
        data: {
          heading: "All Leads"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "lead/map-view/:id",
        component: MapViewComponent,
        data: {
          heading: "Map View"
        },
        canActivate: [AuthGuard]
      },
      // {
      //   path: "lead/no-property",
      //   component: LeadNoPropertyComponent,
      //   data: {
      //     heading: "New Customers"
      //   },
      //   canActivate: [AuthGuard]
      // },
      {
        path: "lead/configure-iot/:id",
        component: IotConfigurationComponent,
        data: {
          heading: "IOT Configuration"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "lead/dashboard",
        component: DashboardComponent,
        data: {
          heading: "Active Leads Dashboard"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "lead/property-list",
        component: PropertyClusterAssignmentComponent,
        data: {
          heading: "New Leads"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "lead/stage-view/:id",
        component: StageViewComponent,
        data: {
          heading: "Lead Details"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "account/users",
        component: UserManagementComponent,
        data: {
          heading: "Amplus Users"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "account/departments",
        component: ClusterDepartmentManagementComponent,
        data: {
          heading: "Cluster Department Mangement"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "clusters/create",
        component: ClusterManagementComponent,
        data: {
          heading: "Cluster Mangement"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "account/admin",
        component: ClusterAdminManagementComponent,
        data: {
          heading: "Cluster Admins"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "account/dep-org",
        component: DepartmentOrganisationComponent,
        data: {
          heading: "Cluster Organization"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "associate/create",
        component: AssociateManagementComponent,
        data: {
          heading: "Associate Management"
        },
        canActivate: [AuthGuard]
      },
      // {
      //   path: "partner/create",
      //   component: PartnerManagementComponent,
      //   data: {
      //     heading: "Partner Management"
      //   },
      //   canActivate: [AuthGuard]
      // },
      {
        path: "partner/link",
        component: PartnerLinkComponent,
        data: {
          heading: "Partner Linking"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "account/partners",
        component: PartnerOrganisationComponent,
        data: {
          heading: "BD Member Organisation"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "account/channel-partners",
        component: EPCPartnerOrganisationComponent,
        data: {
          heading: "Channel Partner Organisation"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "stage/create",
        component: CreateStageComponent,
        data: {
          heading: "Stages"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "stage/access",
        component: DepartmentStageAccessComponent,
        data: {
          heading: "Stage Access"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "stage/field",
        component: CreateStageFieldComponent,
        data: {
          heading: "Stage Field"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "report/lead",
        component: LeadPerformanceComponent,
        data: {
          heading: "Lead Dashboard"
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'report/lead-report',
        component: LeadReportComponent,
        data: {
          heading: "Acquisition Dashboard"
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'report/stage-analytics',
        component: StageAnalyticsComponent,
        data: {
          heading: "Stage Analytics"
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'lead/property-generation/:id',
        component: PropertyGenerationComponent,
        data: {
          heading: 'Lead Generation Reporting'
        },
        canActivate: [AuthGuard]
      },
      {
        path: "report/cluster-generation",
        component: ClusterGenerationComponent,
        data: {
          heading: "Generation Dashboard"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "stage/link",
        component: CreateStageLinkComponent,
        data: {
          heading: "Workflow Path"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "stage/workflow",
        component: WorkflowManagementComponent,
        data: {
          heading: "Cluster Workflow"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "report/activity-dashboard",
        component: ActivityDashboardComponent,
        data: {
          heading: "Activity Dashboard"
        },
        canActivate: [AuthGuard]
      },
      {
        path: "**",
        redirectTo: "error"
      }
    ]
  },
  {
    path: "**",
    redirectTo: "error"
  }
];
