import { Directive } from '@angular/core';
import { ListItemDirective } from './list-item.directive';

@Directive({
  selector: '[appListGroup]'
})
export class ListGroupDirective {
  protected listLinks: Array<ListItemDirective> = [];
  protected openLink: ListItemDirective;

  closeOtherLinks(openLink: ListItemDirective) {
    this.listLinks.forEach((listLink: ListItemDirective) => {
      if(listLink !== openLink) {
        listLink.open = false;
      }
    });
  }

  addLink(link: ListItemDirective) {
    this.listLinks.push(link);
    // console.log('link details');
    // console.log(link);
    // if(this.listLinks.length == 1) {
    //   this.listLinks[0].toggle();
    // }
  }

  removeLink(link: ListItemDirective) {
    let index = this.listLinks.indexOf(link);
    if(index !== -1) {
      this.listLinks.splice(index, 1);
    }
  }

  constructor() { }
}
