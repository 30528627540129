import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  baseURL: any;
  noPropertyURL = 'v1/property/customer/unassigned';
  leadListURL = 'v1/property/list';
  noClusterURL = 'v1/property/unassigned/cluster';
  propertyDetails = '/v1/property/info';
  propertyStage = 'v1/property/stage';
  updateProperty = 'v1/property/new';
  defaultStage = 'v1/property/stage/default';
  firstStage = 'v1/property/info/astral';
  updateStageUrl = 'v1/property/info';
  mapJsonUrl = 'v1/property/map';
  filterData = 'v1/property/filter/data';
  noClusterFilterData = 'v1/property/filter/data/all';
  disableLeadUrl = 'v1/property/info/astral';
  updateStatus = 'v1/property/lmsstatus';
  updateExecutives = '/v1/property/executive';

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  updateNewProperty(data) {
    return this.http.put(this.baseURL + this.updateProperty, data);
  }

  getNoPropertyLeads(data) {
    return this.http.get(this.baseURL + this.noPropertyURL, {
      // headers: new HttpHeaders({
      //   Authorization: localStorage.getItem("token")
      // }),
      params: data
    });
  }

  getLeadList(data) {
    return this.http.get(this.baseURL + this.leadListURL, {
      params: data
    });
  }

  getNoClusterProperty(data) {
    return this.http.get(this.baseURL + this.noClusterURL, {
      // headers: new HttpHeaders({
      //   Authorization: localStorage.getItem("token")
      // }),
      params: data
    });
  }

  getPropertyDetails(uuid) {
    return this.http.get(this.baseURL + this.propertyDetails, {
      params: { property_id: uuid }
    });
  }

  getPropertyStage(prop_uuid, stage_uuid) {
    return this.http.get(this.baseURL + this.propertyStage, {
      params: { stage_id: stage_uuid, property_id: prop_uuid }
    });
  }

  getDefaultStage(data) {
    console.log("<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>");
    console.log(data);
    return this.http.get(this.baseURL + this.defaultStage, {
      params: data
    });
  }

  addFirstStage(data) {
    return this.http.post(this.baseURL + this.firstStage, data);
  }

  updateStage(data) {
    return this.http.put(this.baseURL + this.updateStageUrl, data);
  }

  getMapJson(data) {
    return this.http.get(this.baseURL + this.mapJsonUrl, {
      params: { uuid: data }
    });
  }

  getFilterData(data) {
    if (data != '') {
      return this.http.get(this.baseURL + this.filterData, {
        params: { uuid: data }
      });
    } else {
      return this.http.get(this.baseURL + this.filterData);
    }
  }

  getNoClusterFilterData() {
    return this.http.get(this.baseURL + this.noClusterFilterData);
  }

  disableLead(data) {
    return this.http.delete(this.baseURL + this.disableLeadUrl, {
      params: data
    });
  }

  updateLmsStatus(data) {
    return this.http.put(this.baseURL + this.updateStatus, data);
  }

  updateExecutive(data) {
    return this.http.put(this.baseURL + this.updateExecutives, data);
  }

  downloadLeadList(data) {
    this.http
      .get(this.baseURL + this.leadListURL, {
        params: data,
        responseType: 'arraybuffer'
      })
      .subscribe(res => {
        const timeObject = new Date();
        this.downLoadFile(
          res,
          'application/vnd.mx-excel',
          'lead_list_' +
            timeObject.getDate() +
            '/' +
            timeObject.getMonth() +
            '/' +
            timeObject.getFullYear() +
            '__' +
            timeObject.getHours() +
            '-' +
            timeObject.getMinutes() +
            '-' +
            timeObject.getSeconds() +
            '.xls'
        );
      });
  }

  downloadPropertyList(data) {
    this.http
      .get(this.baseURL + this.noClusterURL, {
        params: data,
        responseType: 'arraybuffer'
      })
      .subscribe(res =>
        {
          const timeObject = new Date();
          this.downLoadFile(
            res,
            'application/vnd.mx-excel',
            'property_list_' +
              timeObject.getDate() +
              '/' +
              timeObject.getMonth() +
              '/' +
              timeObject.getFullYear() +
              '__' +
              timeObject.getHours() +
              '-' +
              timeObject.getMinutes() +
              '-' +
              timeObject.getSeconds() +
              '.xls'
          );
        }
      );
  }

  downLoadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadObject = document.createElement('a');
    downloadObject.href = url;
    downloadObject.download = filename;
    downloadObject.target = '_blank';
    document.body.appendChild(downloadObject);
    downloadObject.click();
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert( 'Please disable your Pop-up blocker and try again.');
  }
}
