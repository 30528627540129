import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class LoggerService {
  baseURL: any;
  deviceURL = "v1/logger/device";
  loggerURL = "v1/logger/info";
  queueStatus = 'v1/logger/manage/queue';
  loggerActivate = 'v1/logger/activate';

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  getLoggerDetails(data) {
    return this.http.get(this.baseURL + this.loggerURL, {
      params: {uuid: data}
    });
  }

  addLogger(data, uuid) {
    return this.http.post(this.baseURL + this.loggerURL, data, {
      params: {property_uuid: uuid}
    });
  }

  updateLogger(data, uuid) {
    return this.http.put(this.baseURL + this.loggerURL, data, {
      params: {property_uuid: uuid}
    });
  }

  updateDevice(data, uuid) {
    return this.http.put(this.baseURL + this.deviceURL, data, {
      params: {property_uuid: uuid}
    });
  }

  addDevice(data, uuid) {
    return this.http.post(this.baseURL + this.deviceURL, data, {
      params: {property_uuid: uuid}
    });
  }

  manageQueue(data) {
    return this.http.put(this.baseURL + this.queueStatus, data);
  }

  changeActivationStatus(data) {
    return this.http.put(this.baseURL + this.loggerActivate, data);
  }
}
