import { Component, OnInit, Inject, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
import { LoggerService } from "../../service/logger/logger.service";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-iot-device-modal",
  templateUrl: "./iot-device-modal.component.html",
  styleUrls: ["./iot-device-modal.component.scss"]
})
export class IotDeviceModalComponent implements OnInit {
  form: any;
  device_provider: any;
  device_type: any;
  lead_uuid: any;
  device_data: any;

  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IotDeviceModalComponent>,
    private fb: FormBuilder,
    private loggerService: LoggerService,
    private toast: MessageToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.device_data = this.data.device_data;
    this.lead_uuid = this.data.uuid;

    if (this.device_data.device_type) {
      this.device_type = [];
      Object.keys(this.device_data.device_type).forEach(val => {
        this.device_type.push({
          key: val,
          value: this.device_data.device_type[val]
        });
      });
    }

    if (this.device_data.device_provider) {
      this.device_provider = [];
      Object.keys(this.device_data.device_provider).forEach(val => {
        this.device_provider.push({
          key: val,
          value: this.device_data.device_provider[val]
        });
      });
    }

    this.form = this.fb.group({
      device_type: ["", Validators.required],
      device_name: ["", Validators.required],
      device_provider: "",
      device_identifier: "",
      sum_factor: [0],
      installed_on: "",
      removed_on: ""
    });

    this.form.valueChanges.subscribe(val => {
      console.log("value changed in the form");
      console.log(this.form);
    });
  }

  submit() {
    let formData = this.form.value;

    if (formData["installed_on"] && formData["installed_on"] != 0) {
      formData["installed_on"] = Math.floor(
        formData["installed_on"].getTime() / 1000
      );
    } else {
      formData["installed_on"] = 0;
    }

    if (formData["removed_on"] != "") {
      formData["removed_on"] = Math.floor(
        formData["removed_on"].getTime() / 1000
      );
    } else {
      formData["removed_on"] = 0;
    }

    console.log("printing the form data");
    console.log(formData);
    this.loggerService.addDevice(formData, this.lead_uuid).subscribe(res => {
      if (res && res["status"]) {
        // this.dialogRef.close();
        this.toast.success("SUCCESSFULLY ADDED THE DEVICE");
        this.reset();
        setTimeout(() => {
          this.status.emit(true);
          this.dialogRef.close();
        }, 300);
      }
    });
  }

  reset() {
    this.form.patchValue({
      device_type: "",
      device_name: "",
      device_provider: "",
      device_identifier: "",
      sum_factor: 0,
      installed_on: "",
      removed_on: ""
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
