import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { LoginService } from "src/app/common/service/login/login.service";
import { AuthService } from "src/app/common/service/auth-service/auth.service";
import { MenuService } from "src/app/common/service/sidebar/menu.service";
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  loop: boolean;
  otp_sent: boolean = false;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private toast: MessageToastService,
    private auth: AuthService,
    private menu: MenuService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      user_string: ["", Validators.compose([Validators.required])],
      otp: ["", Validators.compose([Validators.required])]
    });
  }

  // onSubmit() {
  //   console.log("submitting form");
  //   this.loginService.login(this.form.value).subscribe((result: any) => {
  //     if (result && result.status) {
  //       let data = result.payload;
  //       console.log("payload on login");
  //       console.log(data);
  //       console.log(data.access_default_stage[0]);

  //       if (data.user_type == "amplus" && data.user_roles.length == 0) {
  //         this.toast.error("NO USER ROLES ASSIGNED");
  //       } else {
  //         this.auth.setSession({
  //           user_type: data.user_type,
  //           user_roles: data.user_roles,
  //           user_details: data.user_details,
  //           cluster_details: data.cluster_details ? data.cluster_details : "",
  //           default_access: data.access_default_stage[0],
  //           token: data.token
  //         });
  //         this.firstAccessibleURL();
  //         this.toast.success("SUCCESSFULLY LOGGED IN");
  //       }
  //     } else {
  //       this.toast.error(result["message"].toUpperCase());
  //     }
  //   });
  // }

  firstAccessibleURL() {
    let access: string;
    let accessiblUrl;

    // console.log('in the login component');
    let userInfo = JSON.parse(this.auth.getUser());
    if (userInfo.user_type == "amplus" || userInfo.user_type == "partner") {
      access = userInfo.user_roles[0];
    } else {
      access = userInfo.user_type;
    }

    this.loop = true;
    this.menu.getAll().forEach(group => {
      accessiblUrl = "/" + group.state;
      if (this.loop) {
        group.children.forEach(item => {
          if (this.loop) {
            if (
              item["roles"].indexOf(access) >= 0 &&
              (!item["visible"] || item["visible"] != "false")
            ) {
              accessiblUrl = accessiblUrl + "/" + item.state;
              console.log("navigating to " + accessiblUrl);
              this.router.navigateByUrl(accessiblUrl);
              this.loop = false;
            }
          }
        });
      }
    });
  }

  sendOTP() {
    if (!this.loading) {
      this.loading = true;
      this.loginService
        .sendOTP({ user_string: this.form.get("user_string").value })
        .subscribe(val => {
          this.loading = false;
          if (val && val["status"]) {
            this.otp_sent = true;
            this.toast.success("OTP SENT SUCCESSFULLY");
          } else {
            this.toast.error(val["message"].toUpperCase());
          }
        });
    }
  }

  verifyOTP() {
    if (!this.loading) {
      this.loading = true;
      this.loginService.verifyOTP(this.form.value).subscribe(val => {
        this.loading = false;
        if (val && val["status"]) {
          let data = val["payload"];

          if (data.user_type == "amplus" && data.user_roles.length == 0) {
            this.toast.error("NO USER ROLES ASSIGNED");
          } else {
            let userData = {
              user_type: data.user_type,
              user_roles: data.user_roles,
              user_details: data.user_details,
              cluster_details: data.cluster_details ? data.cluster_details : "",
              default_access: data.access_default_stage[0],
              token: data.token
            };
            this.auth.setSession(userData);
            this.firstAccessibleURL();
            this.toast.success("SUCCESSFULLY LOGGED IN");
          }
        } else {
          this.toast.error(val["message"].toUpperCase());
        }
      });
    }
  }

  resetUserString() {
    this.loading = false;
    this.form.get("otp").setValue("");
    this.otp_sent = !this.otp_sent;
  }
}
