import { Component } from "@angular/core";
import { MenuService } from "../../service/sidebar/menu.service";

import { TranslateService } from "@ngx-translate/core";
import { ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
/* tslint:disable:max-line-length */
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  providers: [MenuService]
})
export class MenuComponent {
  currentLang = "en";
  dropItems = false;
  access: any = [];
  menu: any = [];

  constructor(
    public menuService: MenuService,
    public translate: TranslateService,
    public route: ActivatedRoute
  ) {console.log(route);}

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.user_type == "amplus" || user.user_type == "partner") {
      this.access.push(...user.user_roles);
    } else {
      this.access.push(user.user_type);
    }

    this.menuService.getAll().forEach(group => {
      if (this.access.some(r => group["roles"].indexOf(r) >= 0)) {
        let obj = {
          state: group.state,
          name: group.name,
          type: group.type,
          icon: group.icon,
          children: []
        };
        group.children.forEach(item => {
          if (this.access.some(r => item["roles"].indexOf(r) >= 0) && item['visible']!='false') {
            obj.children.push(item);
          }
        });
        this.menu.push(obj);
      }
    });
    console.log('showing menu contents');
    console.log(this.menu);
  }

  toggleDropdown() {
    this.dropItems = !this.dropItems;
    console.log("toggle working");
  }
}
