import { Component, OnInit, AfterViewInit } from "@angular/core";
import { FieldConfig } from "../field.interface";
import { FormGroup } from "@angular/forms";
declare var $: any;

@Component({
  selector: "app-integer",
  template: `
    <mat-form-field class="demo-full-width" [formGroup]="group">
      <input
        matInput
        step="1"
        [readonly]="readOnly"
        id="{{ field.name }}"
        [formControlName]="field.name"
        [placeholder]="field.label"
        type="text"
        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
      />
      <mat-error *ngIf="group.get(field.name).hasError('required')"
        >{{ field.label }} is required</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('max')"
        >{{ field.label }} exceeds the maximum value of {{group.get(field.name).errors.max['max']}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('min')"
        >{{ field.label }} is less than the minimum value of {{group.get(field.name).errors.min['min']}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('maxlength')"
        >{{ field.label }} exceeds the maximum length of {{group.get(field.name).errors.maxlength['requiredLength']}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('minlength')"
        >{{ field.label }} is less than the minimum length of {{group.get(field.name).errors.minlength['requiredLength']}}</mat-error
      >
    </mat-form-field>
    <br />
  `,
  styleUrls: ["./integer.component.scss"]
})
export class IntegerComponent implements OnInit, AfterViewInit {
  field: FieldConfig;
  group: FormGroup;
  access: String;
  validation: any;
  readOnly: boolean = false;

  constructor() {}

  ngOnInit() {
    if(this.field.name == 'lead-phone_number_1' && (this.field.value != '' && this.field.value != null)) {
      this.readOnly = true;
    }
  }

  ngAfterViewInit() {
    // $("#" + this.field.name)
    //   .keypress(function(event) {
    //     if (
    //         event.which != 46 ||
    //         (event.which == 43 && $(this).val() != "") ||
    //         (event.which == 45 && $(this).val() != "") ||
    //         (($(this).val().indexOf("+") != -1) && (event.which < 48 || event.which > 57)) ||
    //         (($(this).val().indexOf("-") != -1) && (event.which < 48 || event.which > 57))
    //     ) {
    //       event.preventDefault();
    //     }
    //   })
    //   .on("paste", function(event) {
    //     event.preventDefault();
    //   });
  }
}
