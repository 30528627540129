import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClusterService } from '../service/cluster/cluster.service';
import { FormBuilder, Validators } from '@angular/forms';
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-customer-assignment',
  templateUrl: './customer-assignment.component.html',
  styleUrls: ['./customer-assignment.component.scss']
})
export class CustomerAssignmentComponent implements OnInit {
  clusters = [];
  form: any;
  city_geo_point = '';

  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clusterService: ClusterService,
    public dialogRef: MatDialogRef<CustomerAssignmentComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      cluster_id: ["", Validators.required],
      
    });

    this.getClusters();
  }

  ngAfterViewInit() {
    let input = document.getElementById("city-input");
      var restrictOptions = {
        componentRestrictions: { country: "in" },
        fields: ["ALL"]
      };
      var autoComplete = new google.maps.places.Autocomplete(
        input,
        restrictOptions
      );
      google.maps.event.addListener(autoComplete, "place_changed", () => {
        let place = autoComplete.getPlace();
        this.city_geo_point = $("#city-input")[0].value;
        console.log("place object found out");
        console.log(place);

        this.form.get('city').setValue(this.city_geo_point);
        // $('#pac-input').val(this.display_text);
        window.dispatchEvent(new Event("resize"));
      });
  }

  getClusters() {
    this.clusterService.getJunkClusterList().subscribe(res => {
      this.clusters.push({ uuid: "", identifier: "Please select a cluster" });
      this.clusters.push(...res["payload"].clusters);
      // this.clusters = res["payload"].clusters;
      console.log("current cluster list");
      console.log(this.clusters);
    });
  }

  submit() {
    
  }

  cancel() {
    this.dialogRef.close();
  }
}
