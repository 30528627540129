import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivityLogService } from 'src/app/common/service/activity-log/activity-log.service';
import { LoaderService } from 'src/app/common/service/loader/loader.service';

@Component({
  selector: 'app-activity-log-list',
  templateUrl: './activity-log-list.component.html',
  styleUrls: ['./activity-log-list.component.scss']
})
export class ActivityLogListComponent implements OnInit {
  radio_selected = 'all';
  modal_header = 'Activity List';
  all_logs = [];
  display_data = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActivityLogListComponent>,
    private activityLogService: ActivityLogService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    console.log('data from input');
    console.log(this.data);
    this.getDisplayData({value: 'all'});
  }

  getDisplayData(event) {
    let data = {};
    if(this.data.uuid && this.data.uuid != '') {
      data['lead_property_uuid'] = this.data.uuid;
    }
    else {
      data['lead_uuid'] = this.data.lead.uuid;
    }

    this.activityLogService.getActivityLogList(data).subscribe(val => {
      if(val && val['status']) {
        console.log('log list');
        console.log(val['payload']);
        this.all_logs = val['payload'].logs;
        if(event.value == 'all') {
          this.modal_header = 'Activity List';
          this.display_data = this.all_logs;
        } else if(event.value == 'activity_log') {
          this.modal_header = 'Logs';
          this.display_data = this.all_logs.filter(val =>
            val.scheduled_on == null
          );
        } else if(event.value == 'reminder') {
          this.modal_header = 'Reminders';
          this.display_data = this.all_logs.filter(val =>
            val.scheduled_on != null
          );
        } else if(event.value == 'notes') {
          this.modal_header = 'Notes';
          this.display_data = this.all_logs.filter(val =>
            val.scheduled_on == null && val.log_type == 'log'
          );
        }
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

}
