import { Injectable } from "@angular/core";
declare var toastr: any;

@Injectable({
  providedIn: "root"
})
export class MessageToastService {
  constructor() {
    this.setting();
  }

  success(title: string, message?: string) {
    toastr.success(title, message);
  }

  error(title: string, message?: string) {
    toastr.error(title, message);
  }

  setting() {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-center",
      preventDuplicates: false,
      onclick: null,
      showDuration: "500",
      hideDuration: "500",
      timeOut: "1000",
      extendedTimeOut: "500",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut"
    };
  }
}
