import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ClusterService } from "../../service/cluster/cluster.service";
import { LeadService } from "../../service/lead/lead.service";
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-add-cluster',
  templateUrl: './add-cluster.component.html',
  styleUrls: ['./add-cluster.component.scss']
})
export class AddClusterComponent implements OnInit {
  form: any;
  clusters = [];
  row_data: any;
  city_geo_point = '';
  multi_selection = false;

  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddClusterComponent>,
    private toast: MessageToastService,
    private clusterService: ClusterService,
    private leadService: LeadService
  ) {}

  ngOnInit() {
    console.log('data received in this modal');
    console.log(this.data);

    if (!this.data.multi) {
      this.multi_selection = false;
      this.data = this.data.data[0];
    } else {
      this.multi_selection = true;
      this.getClusters();
    }
    this.form = this.fb.group({
      cluster_id: [''],
      address: [this.multi_selection ? '' : this.data.address],
      city: [this.multi_selection ? '' : this.data.city]
    });

    if (!this.multi_selection) {
      this.city_geo_point = this.form.get('city').value;
    }
  }

  ngAfterViewInit() {
    if (!this.multi_selection) {
      let input = document.getElementById('city-input');
      var restrictOptions = {
        componentRestrictions: { country: 'in' },
        fields: ['ALL'],
        types: ['(cities)']
      };
      var autoComplete = new google.maps.places.Autocomplete(
        input,
        restrictOptions
      );
      google.maps.event.addListener(autoComplete, 'place_changed', () => {
        let place = autoComplete.getPlace();
        this.city_geo_point = $('#city-input')[0].value;
        console.log('place object found out');
        console.log(place);

        this.form.get('city').setValue(this.city_geo_point);
        // $('#pac-input').val(this.display_text);
        window.dispatchEvent(new Event('resize'));
      });
    }
  }

  getClusters() {
    this.clusterService.getJunkClusterList().subscribe(res => {
      // this.clusters.push({ uuid: '', identifier: 'Please select a cluster' });
      this.clusters.push(...res['payload'].clusters);
    });
  }

  submit() {
    console.log('current form value');
    console.log(this.form.value);
    if (!this.multi_selection) {
      this.leadService
        .updateNewProperty({
          lead_uuid: this.data.lead.uuid,
          lead_property_uuid: this.data.uuid,
          city: this.city_geo_point ? this.city_geo_point : '',
          address: this.form.get('address').value
            ? this.form.get('address').value
            : ''
        })
        .subscribe(val => {
          if (val && val['status']) {
            this.toast.success('LEAD UPDATED SUCCESSFULLY');
            this.dialogRef.close();
            this.status.emit('success');
          } else {
            this.toast.error('ERROR OCCURED');
          }
        });
    } else {
      // multi selection
      if (this.form.get('cluster_id').value != '') {
        let body = {
          cluster_id: this.form.get('cluster_id').value,
          property_ids: []
        };
        this.data.data.forEach(property => {
          body['property_ids'].push(property.uuid);
        });
        console.log('sending body');
        console.log(body);
        this.clusterService.multiplePropertyAssignment(body).subscribe(val => {
          if (val && val['status']) {
            this.toast.success('CLUSTER ASSIGNED SUCCESSFULLY');
            if (
              this.clusterService.globalCluster.value.uuid ==
              this.form.get('cluster_id').value
            ) {
              this.status.emit('success_match');
            } else {
              this.status.emit('success');
            }
            this.dialogRef.close();
          }
        });
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
