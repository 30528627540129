import { Component, OnInit } from "@angular/core";
import { FieldConfig } from "../field.interface";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"]
})
export class MapComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  dynamic_group: FormGroup = new FormGroup({});
  access: string;
  validation: any;
  text = "";
  value;
  type = "text area";

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    console.log("*****printing map info");
    console.log(this.field);
    // this.group.controls[this.field.name].disable();
    if (this.field.value && this.field.value != 'none' && this.field.value != '' && this.field.value != '{}') {
      console.log('parsing json');
      console.log(this.field.value);
      let val = JSON.parse(this.field.value);
      this.text =
        "Area: " +
        val.area +
        ", Installed Capacity: " +
        val.installed_capacity +
        ", Output: " +
        val.output;
    } else {
      this.text = "";
    }
    this.group.get(this.field.name).setValue((this.field.value != "") ? this.field.value : "{}");
    this.field.value = (this.field.value != "") ? this.field.value : "{}";
  }

  openMap() {
    this.route.params.subscribe(val => {
      val.id;
      console.log('printing the lead uuid obtained');
      console.log(val.id);
      window.open('lead/map-view/' + val.id);
    });
    console.log("map field clicked");
  }
}

