import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageToastService } from '../toast/message-toast.service';
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  baseUrl: any;
  fcmTokenUrl = 'v1/notif/ops/token';

  constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpClient, private toast: MessageToastService) {
    this.angularFireMessaging.messaging.subscribe(_messaging => {
      // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
    this.baseUrl = environment.serverURl;
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      token => {
        console.log(token);
        this.http.post(this.baseUrl + this.fcmTokenUrl, {'token': token, 'platform':'web'}).subscribe(val => {
          if(val && val['status']) {
            this.toast.success('NOTIFICATIONS LINKED');
          }
          else {
            this.toast.error('FIREBASE ERROR');
          }
        });
      },
      err => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(payload => {
      console.log('new message received. ', payload);
      this.currentMessage.next(payload);
    });
  }
}
