import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";
@Component({
  selector: "app-input",
  template: `
    <mat-form-field class="demo-full-width" [formGroup]="group">
      <input
        matInput
        [formControlName]="field.name"
        [placeholder]="field.label"
        type="text"
      />
      <mat-error *ngIf="group.get(field.name).hasError('required')"
        >{{ field.label }} is required</mat-error>
        <mat-error *ngIf="group.get(field.name).hasError('maxlength')"
        >{{ field.label }} exceeds the maximum length of {{group.get(field.name).errors.maxlength['requiredLength']}}</mat-error
      >
      <mat-error *ngIf="group.get(field.name).hasError('minlength')"
        >{{ field.label }} is less than the minimum length of {{group.get(field.name).errors.minlength['requiredLength']}}</mat-error
      >
    </mat-form-field>
    <br />
  `,
  styles: []
})
export class InputComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;

  constructor() {}
  ngOnInit() {
    console.log('********printing input type')
    console.log(this.group);
    console.log(this.field);
    // if(this.field.name == 'name')
    // this.group.get(this.field.name).disable();
  }
}
