import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  baseURL: any;
  loginURL = "v1/authentication/user/login";
  logoutURL = "v1/authentication/logout";
  resetURL = 'v1/authentication/reset/password';
  sendOtpURL = 'v1/authentication/ops/login/otp';
  verifyOtpURL = 'v1/authentication/ops/login/verify';

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  login(data) {
    return this.http.post(this.baseURL + this.loginURL, data, {});
  }

  logOut() {
    return this.http.delete(this.baseURL + this.logoutURL, {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem("token")
      })
    });
  }

  sendOTP(data) {
    return this.http.post(this.baseURL + this.sendOtpURL, data);
  }

  verifyOTP(data) {
    return this.http.post(this.baseURL + this.verifyOtpURL, data);
  }

  resetPassword(oldPass, newPass) {
    return this.http.put(this.baseURL + this.resetURL, {
      'new_password': newPass,
      'old_password': oldPass
    },
    {
      // headers: new HttpHeaders({
      //   Authorization: localStorage.getItem("token")
      // })
    });
  }
}
