import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.scss']
})
export class EditDepartmentComponent implements OnInit {
  form: any;
  @Output() dep_name: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditDepartmentComponent>,
    private fb: FormBuilder,
    private toast: MessageToastService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      'dep_name': this.data['dep_name']
    });
  }

  submit() {
    if(this.form.get('dep_name').value != "") {
      this.dep_name.emit(this.form.get('dep_name').value);
      this.dialogRef.close();
    }
    else{
      this.toast.error('PLEASE ENTER A DEPARTMENT NAME');
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
