import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from '../field.interface';

@Component({
  selector: "app-select",
  template: `
<mat-form-field class="demo-full-width margin-top" [formGroup]="group">
<mat-select [placeholder]="field.label" [formControlName]="field.name">
<mat-option *ngFor="let item of field.options" [value]="item.key">{{item.value}}</mat-option>
</mat-select>
</mat-form-field>
<br/>
`,
  styles: []
})
export class SelectComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  access: string;
  validation: any;
  
  constructor() {}

  ngOnInit() {
    console.log('DROPDOWN - printing the field');
    console.log(this.field);
  }
}
