import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { MessageToastService } from "../../service/toast/message-toast.service";

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss']
})

export class AddPropertyComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private toast: MessageToastService
  ) {}
  ngOnInit() {
    this.form = this.fb.group({
      old_password: ["", Validators.required],
      new_password: ["", Validators.required],
      re_new_password: ["", Validators.required]
    });
  }

  submit() {
    if (
      this.form.controls["new_password"].value ==
      this.form.controls["re_new_password"].value
    ) {
      this.dialogRef.close();
      this.toast.success("PASSWORD CHANGED SUCCESSFULLY");
    } else {
      this.toast.error("PASSWORDS DO NOT MATCH");
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}

