import {
  Component,
  ViewChild,
  ViewEncapsulation,
  OnInit
} from '@angular/core';
import * as go from 'gojs';
import { DataSyncService, DiagramComponent } from 'gojs-angular';
import { StageService } from 'src/app/common/service/stage/stage.service';
import { ClusterService } from 'src/app/common/service/cluster/cluster.service';
import { Subscription } from 'rxjs';
declare var jQuery: any;

@Component({
  selector: 'workflow-management',
  templateUrl: './workflow-management.component.html',
  styleUrls: ['./workflow-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowManagementComponent implements OnInit {
  stageAccessListData = [];
  departmentList = [];
  departmentListDetails = [];
  stageAccessList = [];
  clusterList = [];
  formValid: boolean = false;
  hideGraph: boolean = true;
  hasNoMapping: boolean = false;
  prot = ['r', 't', 'b', 'l'];
  accessColors = {
    "edit":"#c5ed95",
    "view":"#fac146",
    "na":"#c3c7d6",
  };
  private subscription: Subscription = new Subscription();

  constructor(
    private stageService: StageService,
    private clusterService: ClusterService,
  ) {}
  ngOnInit() {
    this.subscription.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          console.log('cluster value change in cluster workflow');
          console.log(val);
          this.getStageLinkDetails(val.uuid);
        }
      })
    );
  }

  @ViewChild('wfDiagram', { static: true })
  public wfDiagramComponent: DiagramComponent;

  public initDiagram(): go.Diagram {
    const $ = go.GraphObject.make;
    const dia = $(go.Diagram, {
      'undoManager.isEnabled': true,
      layout: $(go.LayeredDigraphLayout, {
        layerSpacing: 50,
        columnSpacing: 20
      }),
      initialAutoScale: go.Diagram.UniformToFill,
      model: $(go.GraphLinksModel, {
        linkToPortIdProperty: 'toPort',
        linkFromPortIdProperty: 'fromPort',
        linkKeyProperty: 'key'
      })
    });
    dia.isReadOnly = true;
    dia.nodeTemplate = $(
      go.Node,
      'Auto',
      $(
        go.Panel,
        'Auto',
        $(
          go.Shape,
          'RoundedRectangle',
          { stroke: null },
          new go.Binding('fill', 'color')
        ),
        $(go.TextBlock, { margin: 10 }, new go.Binding('text', 'key'))
      )
    );

    dia.linkTemplate = $(
      go.Link,
      new go.Binding('routing', 'routing'),
      {
        routing: go.Link.AvoidsNodes,
        curve: go.Link.JumpOver,
        name: 'LINK'
      },
      $(go.Shape, { stroke: 'black', strokeWidth: 1 }),
      $(go.Shape, { toArrow: 'Standard' })
    );
    return dia;
  }

  public diagramNodeData: Array<go.ObjectData> = [];
  public diagramLinkData: Array<go.ObjectData> = [];
  public diagramDivClassName: string = 'wfDiagramDiv';
  public diagramModelData = { prop: 'value' };

  getStageLinkDetails(cluster_id) {
    let queryParams = 'uuid=' + cluster_id;
    this.stageService
      .getStageLinkDetails(queryParams)
      .subscribe((result: any) => {
        if (result.status) {
          jQuery('#header-close').click();
          var count = 0;
          this.diagramNodeData = [];
          this.diagramLinkData = [];
          result.payload.forEach(element => {
            this.diagramNodeData.push({
              key: element.current_stage.display_name,
              color: this.accessColors[element.current_stage.max_user_access],
              uuid: element.current_stage.uuid
            });
            var iPort = 0;
            element.next_stages.forEach(stage => {
              this.diagramNodeData.push({
                key: stage.display_name,
                color: this.accessColors[stage.max_user_access],
                uuid: stage.uuid
              });
              this.diagramLinkData.push({
                key: --count,
                from: element.current_stage.display_name,
                to: stage.display_name,
                //  fromPort: this.prot[iPort],
                //  toPort: this.prot[3 - iPort],
                fromPort: 'l',
                toPort: 't'
              });
              iPort = iPort > 2 ? 0 : iPort + 1;
            });
          });
          console.log(this.diagramNodeData);
          if (this.diagramLinkData.length) {
            this.hideGraph = false;
            this.hasNoMapping = false;
            this.initDiagram();
          } else {
            this.hasNoMapping = true;
            this.hideGraph = true;
          }
        }
      });
  }
}
