import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatMenuTrigger } from '@angular/material/menu';
import * as shape from 'd3-shape';
import { GenerationService } from 'src/app/common/service/generation/generation.service';
import { ActivatedRoute } from '@angular/router';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { FormBuilder } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-property-generation',
  templateUrl: './property-generation.component.html',
  styleUrls: ['./property-generation.component.scss']
})
export class PropertyGenerationComponent implements OnInit {
  selectedProperty: any;
  graph_freq: number = 1;
  tab_selected = 0;
  status = true;
  totalHomeConsumption = 0;
  energy_flow: any;
  chartData: any = [{ name: '', series: [] }];
  canvasDrawn = false;
  tileData: any;
  leadName = '';
  form: any;
  propertyID = '';
  leadPhoneNumber = '';
  totalEnergyGenerated = '';
  showTiles = true;
  minDate: any = '';
  maxDate: any = '';
  monthMap = {
    '1': 'January',
    '2': 'February',
    '3': 'March',
    '4': 'April',
    '5': 'May',
    '6': 'June',
    '7': 'July',
    '8': 'August',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
  };

  reverseMonthMap = {
    Jan: '0',
    Feb: '1',
    Mar: '2',
    Apr: '3',
    May: '4',
    Jun: '5',
    Jul: '6',
    Aug: '7',
    Sep: '8',
    Oct: '9',
    Nov: '10',
    Dec: '11'
  };

  consumptionLineGraphData = [];
  generationLineGraphData = [];
  consumptionBarGraphData = [{ name: '', series: [{ name: '', value: 0 }] }];
  consumptionBarGraphStatus = false;
  generationBarGraphData = [];

  //chart Options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  tooltipDisabled = false;
  xAxisLabel = 'Hours';
  tooltip = 'Hour';
  showYAxisLabel = true;
  yAxisLabel = 'Power Generated(kW)';
  showGridLines = true;
  innerPadding = 0;
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  noBarWhenZero = false;

  // graph_freq color scheme
  colors1 = ['#5c6bc0', '#f59240', '#29b6f6', '#ffee58', '#ef5350', '#868e96'];
  colors = ['#5c6bc0'];
  colorScheme = {
    domain: this.colors
  };
  areaColorScheme = { domain: this.colors1 };
  schemeType = 'ordinal';

  // line interpolation
  curve = shape.curveLinear;
  rangeFillOpacity = 0.15;

  // line; area
  autoScale = true;
  timeline = false;

  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  constructor(
    private generationService: GenerationService,
    private route: ActivatedRoute,
    private menuToggle: MenuToggleService,
    private toast: MessageToastService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.tileData = [];
    this.maxDate = new Date();

    // filter for daily, monthly, yearly
    this.form = this.fb.group({
      day: [new Date()],
      month: [new Date()],
      year: [new Date()]
    });

    // getting property uuid from url
    this.route.params.subscribe(val => {
      this.selectedProperty = { uuid: val.id };
      // getting property details
      this.generationService
        .getPropertyDetails({ uuid: val.id })
        .subscribe(val => {
          if (val && val['status']) {
            console.log('property details');
            console.log(val);
            this.minDate = new Date(val['payload'].logger_installed_on * 1000);
            this.leadName =
              val['payload'].lead_details.first_name +
              ' ' +
              val['payload'].lead_details.last_name;
            this.leadPhoneNumber = val['payload'].lead_details.phone_number_1;
            this.propertyID = val['payload'].property_id;
          }
        });
      // on initial page load, first populating the tiles
      this.getTileData(1);
      // this.getEnergyFlow();
    });
    // closing the sidemenu on page load
    this.menuToggle.toggleMenu(false);

    // resizing the content whenever menu is toggled
    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    });
  }

  // drawing canvas arrows
  drawCanvas() {
    this.canvasDrawn = true;
    setTimeout(() => {
      let canvas = document.querySelector('canvas');

      // blurry line fix
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      let c = canvas.getContext('2d');

      let width = canvas.width;
      let height = canvas.height;

      // making a triangle
      c.setLineDash([10, 3]); // [a,b] -> a is dash length, b is space length

      // right slant
      c.beginPath();
      c.moveTo(width / 2, height * 0.12);
      c.lineTo(width * 0.8, height * 0.84);
      c.strokeStyle = '#f59240';
      c.lineWidth = 6;
      c.stroke();

      // left slant
      c.beginPath();
      c.moveTo(width * 0.8, height * 0.84);
      c.lineTo(width * 0.2, height * 0.84);
      c.strokeStyle = '#f59240';
      c.lineWidth = 6;
      c.stroke();

      // bottom line
      c.beginPath();
      c.moveTo(width * 0.2, height * 0.84);
      c.lineTo(width / 2, height * 0.12);
      c.strokeStyle = '#f59240';
      c.lineWidth = 6;
      c.stroke();

      c.setLineDash([]);
      c.lineWidth = 8;
      // left arrow
      c.beginPath();
      c.moveTo(width * 0.335, height * 0.516);
      c.lineTo(
        width * 0.335 + (width / 18) * Math.cos(Math.PI / 6),
        height * 0.516 - (width / 18) * Math.sin(Math.PI / 6)
      );
      c.stroke();

      c.beginPath();
      c.moveTo(width * 0.335, height * 0.516);
      c.lineTo(
        width * 0.335 + (width / 18) * Math.cos(Math.PI / 2),
        height * 0.516 - (width / 18) * Math.sin(Math.PI / 2)
      );
      c.stroke();

      // right arrow
      c.beginPath();
      c.moveTo(width * 0.665, height * 0.516);
      c.lineTo(
        width * 0.665 + (width / 18) * Math.cos(Math.PI / 2),
        height * 0.516 - (width / 18) * Math.sin(Math.PI / 2)
      );
      c.stroke();

      c.beginPath();
      c.moveTo(width * 0.665, height * 0.516);
      c.lineTo(
        width * 0.665 + (width / 18) * Math.cos((Math.PI / 6) * 5),
        height * 0.516 - (width / 18) * Math.sin((Math.PI / 6) * 5)
      );
      c.stroke();

      // bottom arrow
      c.beginPath();
      c.moveTo(width * 0.53, height * 0.84);
      c.lineTo(
        width * 0.53 + (width / 18) * Math.cos((Math.PI / 6) * 7),
        height * 0.84 - (width / 18) * Math.sin((Math.PI / 6) * 7)
      );
      c.stroke();

      c.beginPath();
      c.moveTo(width * 0.53, height * 0.84);
      c.lineTo(
        width * 0.53 + (width / 18) * Math.cos((Math.PI / 6) * 5),
        height * 0.84 - (width / 18) * Math.sin((Math.PI / 6) * 5)
      );
      c.stroke();
    }, 300);
  }

  // getting generation data
  getGenerationData(option) {
    console.log('making a call to ' + this.selectedProperty.uuid);

    let timestamp = this.getTimestamp(option);

    this.generationService
      .getGenerationData({
        filter_by: option,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp
      })
      .subscribe(val => {
        if (val && val['status']) {
          if (val['payload'].total_energy == -1) {
            this.status = false;
            this.toast.error('NO DATA FOUND');
          } else {
            this.status = true;
            let dataLength = val['payload'].data.length;

            console.log('generation data');
            console.log(val);

            // line and bar graph require data in different formats
            if (option == 1) {
              this.generationLineGraphData = this.correctLineGraphData(
                val['payload'].data
              );
            } else {
              this.generationBarGraphData = this.correctBarGraphData(
                val['payload'].data
              );

              // if there are only 4 (or less) columns then adding extra columns
              if (dataLength < 4) {
                this.generationBarGraphData = this.addEmptyColumns(
                  this.generationBarGraphData,
                  dataLength
                );
              }
            }
          }
          this.totalEnergyGenerated = val['payload'].total_energy;
        } else {
          this.status = false;
          this.toast.error('NO DATA FOUND');
        }
      });
  }

  // adding empty columns in bar graph data
  addEmptyColumns(data, length) {
    let empty = '  ';
    for (let i = 0; i < 5 - length; i++) {
      data.push({
        name: empty.repeat(i + 1),
        value: 0
      });
    }

    return data;
  }

  // different frequency daily, monthly, yearly require different formats of timestamp in API call
  getTimestamp(option) {
    let timestamp = 0;
    if (option == 1) {
      timestamp = Math.floor(this.form.get('day').value.getTime() / 1000);
    } else if (option == 2) {
      console.log('inside month timestamp');
      console.log(this.form.get('month').value);
      console.log('passing values');
      console.log(
        'year: ' +
          this.form
            .get('month')
            .value.toString()
            .split(' ')[3]
      );
      console.log(
        'month: ' +
          this.reverseMonthMap[
            this.form
              .get('month')
              .value.toString()
              .split(' ')[1]
          ]
      );
      let timeObject = new Date(
        Date.UTC(
          this.form
            .get('month')
            .value.toString()
            .split(' ')[3],
          this.reverseMonthMap[
            this.form
              .get('month')
              .value.toString()
              .split(' ')[1]
          ]
        )
      );
      timestamp = Math.floor(timeObject.getTime() / 1000);
      console.log('final time object');
      console.log(timestamp);
    } else if (option == 3) {
      let timeObject = new Date(
        Date.UTC(
          this.form
            .get('year')
            .value.toString()
            .split(' ')[3],
          this.reverseMonthMap[
            this.form
              .get('year')
              .value.toString()
              .split(' ')[1]
          ]
        )
      );
      timestamp = Math.floor(timeObject.getTime() / 1000);
    }

    return timestamp;
  }

  // getting tile information
  getTileData(option) {
    let timestamp = this.getTimestamp(option);
    this.generationService
      .getBenefits({
        filter_by: option,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp
      })
      .subscribe(val => {
        if (val && val['status']) {
          this.showTiles = true;
          this.tileData = [];
          this.tileData.push(...val['payload'].benefits);
          this.tileData.push(val['payload'].money_saved);
          // this.totalEnergyGenerated = val['payload'].total_energy;
        } else {
          this.showTiles = false;
          this.toast.error(val['message'].toUpperCase());
        }
      });
  }

  // sidenavToggle() {
  //   this.sidenavBar.toggle();
  // }

  // refreshing the page whenever freq (daily, monthly, yearly) is changed
  radioChange(event) {
    if (event.source._checked == true) {
      this.chartData = [];
      this.graph_freq = event.value;
      this.changeLabel(this.graph_freq);
      this.pageRefresh();
    }
  }

  // changing graph labels based on freq
  changeLabel(option) {
    switch (option) {
      case 1:
        this.xAxisLabel = 'Hours';
        this.yAxisLabel = 'Power Generated(kW)';
        this.tooltip = 'Hour';
        break;
      case 2:
        this.xAxisLabel = 'Days';
        this.yAxisLabel = 'Energy Generated(kWh)';
        this.tooltip = 'Day';
        break;
      case 3:
        this.xAxisLabel = 'Months';
        this.yAxisLabel = 'Energy Generated(kWh)';
        this.tooltip = 'Month';
        break;
      case 4:
        this.xAxisLabel = 'Years';
        this.yAxisLabel = 'Energy Generated(kWh)';
        this.tooltip = 'Year';
        break;
      default:
        this.xAxisLabel = 'Days';
        this.yAxisLabel = 'Energy Generated(kWh)';
        this.tooltip = 'Day';
    }
  }

  // line graph data correction
  correctLineGraphData(data, series = 'Power'): any {
    let new_data = [];
    Object.keys(data).map((key: any) => {
      var point;
      if (data[key]) point = data[key];
      else {
        point = 0;
      }
      key = parseFloat(key);
      new_data.push({ value: point, name: key });
    });
    new_data = [{ name: series, series: new_data }];
    return new_data;
  }

  // bar graph data correction
  correctBarGraphData(data): any {
    let new_data = [];
    Object.keys(data).map((key: any) => {
      var point;
      if (data[key]) point = data[key];
      else point = 0;
      if (this.graph_freq == 1) {
        key = parseFloat(key);
      } else if (this.graph_freq == 3) {
        key = this.monthMap[key];
      }
      new_data.push({ name: key, value: point });
    });

    if (Object.keys(new_data).length < 10) {
      for (let i = 0; i < 17 - Object.keys(new_data).length; i++) {
        let empty = ' ';
        new_data.push({
          name: empty.repeat(i + 1),
          value: 0
        });
      }
    }
    return new_data;
  }

  // converting tooltip time (float to hours, time format)
  getTooltipTime(text) {
    text = text.toString();
    let hour = text.split('.')[0];
    let minutes = NaN;
    if (text.split('.')[1]) {
      minutes = parseInt(
        text.split('.')[1].length == 1
          ? text.split('.')[1] + '0'
          : text.split('.')[1]
      );
      minutes = Math.floor(minutes * 0.6);
    }

    return (
      hour +
      ':' +
      (!isNaN(minutes)
        ? (Math.floor(minutes / 10) == 0 ? '0' : '') + minutes.toString()
        : '00')
    );
  }

  onTabChanged(event) {
    console.log('tab changed');
    // window.dispatchEvent(new Event("resize"));
    console.log(event);
    this.tab_selected = event.index;
    this.pageRefresh();
  }

  // getting energy data
  getNetEnergyData(option) {
    let timestamp = this.getTimestamp(option);
    this.generationService
      .getNetEnergyData({
        filter_by: option,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp
      })
      .subscribe(val => {
        if (val && val['status']) {
          console.log('net energy data found out');
          console.log(val);

          if (val['payload'].total_energy != -1) {
            if (option == 1) {
              this.consumptionLineGraphData = [];
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val['payload'].consumption_data,
                  'Consumption Data'
                )[0]
              );
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val['payload'].generation_data,
                  'Generation Data'
                )[0]
              );
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val['payload'].grid_export,
                  'Grid Export Data'
                )[0]
              );
              console.log('final consumption data found out - for daily');
              console.log(this.consumptionLineGraphData);
            } else {
              let dataList = [
                { name: 'Consumption', data: val['payload'].consumption_data },
                { name: 'Generation', data: val['payload'].generation_data },
                { name: 'Grid Export', data: val['payload'].grid_export }
              ];
              this.consumptionBarGraphData = this.correctGroupedBarGraphData(
                dataList
              );
              this.consumptionBarGraphStatus = true;
              console.log(
                'final consumption data found out - for monthly/yearly/lifetime'
              );
              console.log(this.consumptionBarGraphData);
            }
            if (option == 3 || option == 4) {
              this.noBarWhenZero = true;
            } else {
              this.noBarWhenZero = false;
            }
          } else {
            this.status = false;
            this.toast.error('NO DATA FOUND');
          }
          this.totalEnergyGenerated = val['payload'].total_energy;
        } else {
          this.status = false;
          this.toast.error('NO DATA FOUND');
        }
      });
  }

  // getting energy flow data
  getEnergyFlow() {
    let timestamp = this.getTimestamp(this.graph_freq);
    this.generationService
      .getEnergyFlow({
        filter_by: this.graph_freq,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp
      })
      .subscribe(val => {
        if (val && val['status']) {
          if (val['payload'].solar_generation != -1) {
            this.energy_flow = val['payload'] ? val['payload'] : [];
            console.log('energy flow data fetched');
            console.log(this.energy_flow);
          } else {
            this.status = false;
            this.toast.error('NO DATA FOUND');
          }
        } else {
          this.status = false;
          this.toast.error('NO DATA FOUND');
        }
      });
  }

  // getting consumption data
  getConsumptionData() {
    let option = this.graph_freq;
    let timestamp = this.getTimestamp(option);
    this.generationService
      .getConsumptionData({
        filter_by: option,
        property_uuid: this.selectedProperty.uuid,
        timestamp: timestamp
      })
      .subscribe(val => {
        if (val && val['status']) {
          console.log('consumption data found out');
          console.log(val);

          if (val['payload'].total_home_consumption == -1) {
            this.status = false;
            this.toast.error('NO DATA FOUND');
          } else {
            if (option == 1) {
              this.consumptionLineGraphData = [];
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val['payload'].solar_to_home,
                  'Solar To Home'
                )[0]
              );
              this.consumptionLineGraphData.push(
                this.correctLineGraphData(
                  val['payload'].grid_to_home,
                  'Grid To Home'
                )[0]
              );
              console.log('final consumption data found out - for daily');
              console.log(this.consumptionLineGraphData);
            } else {
              let dataList = [
                { name: 'Solar to home', data: val['payload'].solar_to_home },
                { name: 'Grid to home', data: val['payload'].grid_to_home }
              ];
              this.consumptionBarGraphData = this.correctGroupedBarGraphData(
                dataList
              );
              this.consumptionBarGraphStatus = true;
              console.log(
                'final consumption data found out - for monthly/yearly/lifetime'
              );
              console.log(this.consumptionBarGraphData);
            }
          }
          this.totalHomeConsumption = val['payload'].total_home_consumption;
        } else {
          this.toast.error('NO DATA FOUND');
          this.status = false;
        }
      });
  }

  pageRefresh() {
    switch (this.tab_selected) {
      case 0: // benefits tab
        this.getTileData(this.graph_freq);
        break;
      case 1: // generation tab
        this.getGenerationData(this.graph_freq);
        break;
      case 2: // net energy tab
        window.dispatchEvent(new Event('resize'));
        this.getNetEnergyData(this.graph_freq);
        break;
      case 3: // consumption tab
        window.dispatchEvent(new Event('resize'));
        this.getConsumptionData();
        break;
      case 4: // energy tab
        this.getEnergyFlow();
        if (!this.canvasDrawn) {
          this.drawCanvas();
        }
        break;
    }
  }

  correctGroupedBarGraphData(dataList) {
    let finalData = [];

    Object.keys(dataList[0].data).forEach(key => {
      let obj = {};
      obj['name'] = this.graph_freq === 3 ? this.monthMap[key.toString()] : key;
      obj['series'] = [];
      dataList.forEach(data => {
        obj['series'].push({
          name: data.name,
          value: data.data[key] ? data.data[key] : 0
        });
      });
      finalData.push(obj);
    });

    return finalData;
  }

  // function called whenever date is selected
  onChange(event) {
    console.log('date changed');
    console.log(event);
    this.pageRefresh();
  }

  disableViewChange() {
    setTimeout(() => {
      $(
        'button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button'
      ).css('pointer-events', 'none');
    }, 300);
  }
}
