import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { FormBuilder, Validators } from "@angular/forms";
import { LinkService } from "src/app/common/service/link/link.service";
import { AccountService } from "src/app/common/service/account/account.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DragulaService } from "ng2-dragula";
import { Subject, Subscription } from "rxjs";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
declare var $: any;

@Component({
  selector: "app-partner-organisation",
  templateUrl: "./partner-organisation.component.html",
  styleUrls: ["./partner-organisation.component.scss"],
  viewProviders: [DragulaService]
})
export class PartnerOrganisationComponent implements OnInit {
  sidenavText = "ADD PARTNER";
  form: any;
  isEditMode = false;
  formValid = false;
  dragBin = false;
  match = false;
  dropTarget = "addbin";
  partnerList = [];
  searchList = [];
  globalSearchQuery: String = '';
  partnerManagerList = [];
  selectedCluster: any;
  subscriptionList = new Subscription();

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private linkService: LinkService,
    private accountService: AccountService,
    private toast: MessageToastService,
    private dragulaService: DragulaService,
    private clusterService: ClusterService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      cluster_id: [""],
      uuid: [null],
      name: ["", Validators.required],
      country_code: ["91"],
      phone_number: ["", Validators.required],
      pan_card: [""],
      is_sales: [true],
      is_epc: [false],
      aadhar_card: [""],
      bank_name: [""],
      bank_branch: [""],
      bank_account_type: ["Saving"],
      bank_account_number: [""],
      bank_account_ifsc: [""],
      company_name: [""],
      gst_number: [""],
      email_id: ["", Validators.required],
      city: [""],
      district: [""],
      pincode: [""],
      partner_code: [""],
      address: ""
    });

    this.subscriptionList.add(
      this.form.valueChanges.subscribe(() => {
        this.formValid = <boolean>(this.form.valid && this.checkPartnerType());
      })
    );

    this.subscriptionList.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          this.selectedCluster = val;
          this.form.get("cluster_id").setValue(this.selectedCluster.uuid);
          this.getLinkedPartnerDetails();
          this.getPartnerList();
        }
      })
    );

    this.dragulaService.destroy("task-group");
    this.dragulaService.createGroup("task-group", {
      accepts: (el, target, source, sibling) => {
        this.match = false;
        Array.from(target.childNodes).forEach(val => {
          if (
            val.childNodes[0] &&
            val.childNodes[0].childNodes[2] &&
            val.childNodes[0].childNodes[2].textContent &&
            val.childNodes[0].childNodes[2].textContent ==
              el.childNodes[0].childNodes[2].textContent
          ) {
            this.match = true;
          }
        });

        if (
          target.getAttribute("id") ==
          el.childNodes[0].childNodes[2].textContent.trim()
        ) {
          this.match = true;
        }

        return (
          !this.match &&
          (source.getAttribute("id") == "partner-list" ||
            (source.getAttribute("id") != "partner-list" &&
              target.getAttribute("id") == "dragbin"))
        );
      },
      copy: (el, source) => {
        if (source.getAttribute("id") == "partner-list") {
          return true;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.subscriptionList.add(
      this.dragulaService
        .drag("task-group")
        .subscribe(({ name, el, source }) => {
          if (source.getAttribute("id") != "partner-list") {
            this.dragBin = true;
          }
        })
    );

    this.subscriptionList.add(
      this.dragulaService.dragend("task-group").subscribe(({ el }) => {
        this.dragBin = false;
      })
    );

    this.subscriptionList.add(
      this.dragulaService
        .drop("task-group")
        .subscribe(({ name, el, target, source, sibling }) => {
          if (target) {
            if (target.getAttribute("id") == "addbin") {
              if (target) {
                if (target.childNodes.length != 0) {
                  Array.from(target.childNodes).forEach(val => {
                    if (val["id"] != "tile-info") {
                      val.remove();
                    }
                  });
                }

                let match = false;
                console.log("partner manager list right now");
                console.log(this.partnerManagerList);
                this.partnerManagerList.forEach(pManager => {
                  if (
                    pManager.uuid ==
                    el.childNodes[0].childNodes[2].textContent.trim()
                  ) {
                    match = true;
                  }
                });
                if (!match) {
                  this.partnerManagerList.push({
                    name: el.childNodes[0].childNodes[0].textContent.trim(),
                    uuid: el.childNodes[0].childNodes[2].textContent.trim()
                  });
                }
              }
            } else if (target.getAttribute("id") == "dragbin") {
              this.linkService
                .deletePartnerLink({
                  cluster_id: this.selectedCluster.uuid,
                  partner_from_id: source.getAttribute("id"),
                  partner_to_id: el.childNodes[0].childNodes[2].textContent
                })
                .subscribe(val => {
                  if (val && val["status"]) {
                    this.getLinkedPartnerDetails();
                    this.getPartnerList();
                    this.toast.success("PARTNER DELETED SUCCESSFULLY");
                  } else {
                    this.toast.error("OPERATION FAILED");
                  }
                });
            } else {
              this.linkService
                .createPartnerLink({
                  cluster_id: this.selectedCluster.uuid,
                  partner_from_id: target.getAttribute("id"),
                  partner_to_ids: [el.childNodes[0].childNodes[2].textContent]
                })
                .subscribe(val => {
                  if (val && val["status"]) {
                    this.getLinkedPartnerDetails();
                    this.getPartnerList();
                    this.toast.success("PARTNER LINKED SUCCESSFULLY");
                  } else {
                    this.toast.error("OPERATION FAILED");
                  }
                });
            }
          }
        })
    );
  }

  getLinkedPartnerDetails() {
    this.linkService
      .getLinkedPartnerList({ cluster_id: this.selectedCluster.uuid })
      .subscribe(val => {
        if (val && val["status"]) {
          console.log("partner linked list found out");
          console.log(val);
          this.partnerManagerList = [];
          val["payload"].forEach(pManager => {
            if(pManager.current_partner.is_sales == 1){
              let obj = {
                name: pManager.current_partner.name,
                uuid: pManager.current_partner.uuid,
                partners: [...pManager.next_partners]
              };
              this.partnerManagerList.push(obj);
            }
          });
        } else {
          this.toast.error("OPERATION FAILED");
        }
      });
  }

  getPartnerList() {
    this.accountService
      .getPartnerAccountList({ cluster_id: this.selectedCluster.uuid })
      .subscribe(val => {
        console.log("partner list found out");
        console.log(val);
        this.partnerList = [];
        val["payload"].data.forEach((item) => {
            if(item.is_sales == 1){
              this.partnerList.push(item);
            }
        });
        this.searchList = this.partnerList;
        (<HTMLInputElement>document.getElementById('partner-search')).value = <string>this.globalSearchQuery;
        this.searchQuery(this.globalSearchQuery);
      });
  }

  checkPartnerType(): boolean {
    if (
      this.form.get("is_sales").value == true ||
      this.form.get("is_epc").value == true
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleSidebar() {
    this.reset();
    this.sidenavBar.toggle();
    this.isEditMode = false;
    this.sidenavText = "ADD PARTNER";
  }

  submit() {
    if (this.form.valid) {
      console.log("sending form data to new api");
      console.log(this.form.value);
      if (this.isEditMode) {
        this.accountService
          .updatePartnerUserAccount(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toggleSidebar();
              this.toast.success("PARTNER UPDATED SUCCESSFULLY");
              // this.getUserDetails();
              this.getLinkedPartnerDetails();
              this.getPartnerList();
            } else {
              this.toast.error(result.message.toUpperCase());
            }
          });
      } else {
        this.accountService
          .savePartnerUserAccount(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toggleSidebar();
              this.toast.success("PARTNER CREATED SUCCESSFULLY");
              // this.getUserDetails();
              this.getLinkedPartnerDetails();
              this.getPartnerList();
            } else {
              this.toast.error(result.message.toUpperCase());
            }
          });
      }
    }
  }

  reset() {
    this.form.reset();
    this.form.patchValue({
      cluster_id: this.selectedCluster.uuid,
      uuid: [null],
      name: "",
      country_code: "91",
      phone_number: "",
      pan_card: "",
      is_sales: true,
      is_epc: false,
      aadhar_card: "",
      bank_name: "",
      bank_branch: "",
      bank_account_type: "Saving",
      bank_account_number: "",
      bank_account_ifsc: "",
      company_name: "",
      gst_number: "",
      email_id: "",
      city: "",
      district: "",
      pincode: "",
      partner_code: "",
      address: ""
    });
  }

  secondaryOption() {
    if (this.isEditMode) {
      this.accountService
        .deletePartnerUserAccount({
          uuid: this.form.get("uuid").value,
          cluster_id: this.selectedCluster.uuid
        })
        .subscribe(val => {
          if (val && val["status"]) {
            this.toast.success("PARTNER DELETED SUCCESSFULLY");
            this.getLinkedPartnerDetails();
            this.getPartnerList();
            this.toggleSidebar();
          } else {
            this.toast.error(val["message"].toUpperCase());
          }
        });
    } else {
      this.reset();
    }
  }

  editPartner(partner) {
    console.log("editing the partner");
    console.log(partner);
    this.form.reset();
    this.form.patchValue({
      cluster_id: this.selectedCluster.uuid,
      uuid: partner.uuid,
      name: partner.name,
      country_code: partner.country_code,
      phone_number: partner.phone_number,
      pan_card: partner.pan_card,
      is_sales: partner.is_sales,
      is_epc: partner.is_epc,
      aadhar_card: partner.aadhar_card,
      bank_name: partner.bank_name,
      bank_branch: partner.bank_branch,
      bank_account_type: partner.bank_account_type,
      bank_account_number: partner.bank_account_number,
      bank_account_ifsc: partner.bank_account_ifsc,
      company_name: partner.company_name,
      gst_number: partner.gst_number,
      email_id: partner.email_id,
      city: partner.city,
      district: partner.district,
      pincode: partner.pincode,
      partner_code: partner.partner_code,
      address: partner.address
    });

    this.sidenavBar.toggle();
    this.isEditMode = true;
    this.sidenavText = "EDIT PARTNER";
  }

  searchQuery(value) {
    console.log("search changed");
    console.log(value);
    
    this.globalSearchQuery = value;

    this.searchList = this.partnerList.filter(val =>
      val.name.toLowerCase().includes(value.toLowerCase())
    );
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
