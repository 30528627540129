import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  baseURL: any;
  leadReportListURL = 'v1/report/analytics/lead/filter/data';
  leadReportURL = 'v1/report/analytics/lead/perfomance';
  clusterGeneration = 'v1/report/generation/cluster';
  globalLeadURL = 'v1/report/analytics/lead';
  globalPropertyURL = 'v1/report/analytics/property';
  globalSourceURL = 'v1/report/analytics/source/list';
  leadMapURL = 'v1/report/analytics/map';
  analyticsPerformenceURL = 'v1/report/analytics/ops/perfomance';
  analyticsPerformenceDetailURL = 'v1/report/analytics/ops/perfomance/detail';
  stageAnalyticsUrl = 'v1/report/analytics/stage/property';
  statusAnalyticsUrl = 'v1/report/analytics/lmsstatus/property';

  constructor(private http: HttpClient) {
    this.baseURL = environment.serverURl;
  }

  getStageAnalytics(queryParams) {
    return this.http.get(this.baseURL + this.stageAnalyticsUrl, {
      params: queryParams
    });
  }

  getStatusAnalytics(queryParams) {
    return this.http.get(this.baseURL + this.statusAnalyticsUrl, {
      params: queryParams
    });
  }

  getLeadReportList(queryParams) {
    return this.http.get(this.baseURL + this.leadReportListURL, {
      params: queryParams
    });
  }

  getLeadReport(queryParams) {
    return this.http.get(this.baseURL + this.leadReportURL, {
      params: queryParams
    });
  }

  getClusterGeneration(queryParams) {
    return this.http.get(this.baseURL + this.clusterGeneration, {
      params: queryParams
    });
  }

  getGlobalLeadReport(queryParams) {
    return this.http.get(this.baseURL + this.globalLeadURL, {
      params: queryParams
    });
  }

  getGlobalPropertyReport(queryParams) {
    return this.http.get(this.baseURL + this.globalPropertyURL, {
      params: queryParams
    });
  }

  getLeadMap(queryParams) {
    return this.http.get(this.baseURL + this.leadMapURL, {
      params: queryParams
    });
  }

  getGlobalSource(queryParams) {
    return this.http.get(this.baseURL + this.globalSourceURL, {
      params: queryParams
    });
  }

  getActivityLogDetails(queryParams) {
    return this.http.get(this.baseURL + this.analyticsPerformenceURL, {
      params: queryParams
    });
  }
  getPerformenceDetails(queryParams) {
    return this.http.get(this.baseURL + this.analyticsPerformenceDetailURL, {
      params: queryParams
    });
  }
}
