import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageToastService } from '../../service/toast/message-toast.service';
import { LoggerService } from '../../service/logger/logger.service';

@Component({
  selector: 'app-lead-activation-modal',
  templateUrl: './lead-activation-modal.component.html',
  styleUrls: ['./lead-activation-modal.component.scss']
})
export class LeadActivationModalComponent implements OnInit {
  button_text: string;

  @Output() status: EventEmitter<any> = new EventEmitter();


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LeadActivationModalComponent>,
    private loggerService: LoggerService,
    private toast: MessageToastService
  ) { }

  ngOnInit() {
    if(this.data.activation_status == true) {
      this.button_text = 'DEACTIVATE';
    }
    else {
      this.button_text = 'ACTIVATE';
    }
  }

  status_toggle() {
    let formData = {uuid: this.data.uuid, active_status: !this.data.activation_status};
    this.loggerService.changeActivationStatus(formData).subscribe(val => {
      if(val && val['status']) {
        this.toast.success('ACTIVATION STATUS CHANGED SUCCESSFULLY');
        this.status.emit('success');
      }
      else {
        this.toast.error('OPERATION FAILED');
        this.status.emit('error');
      }
      this.dialogRef.close();
    });
  }

  option_no() {
    this.dialogRef.close();
  }

}
