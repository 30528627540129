import { Component, EventEmitter, Output, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { LoginService } from "../../service/login/login.service";
import { AuthService } from "../../service/auth-service/auth.service";
import { SignOutComponent } from "../sign-out/sign-out.component";
import { ClusterService } from "../../service/cluster/cluster.service";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { MenuToggleService } from "../../service/menu-toggle/menu-toggle.service";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {
  userName: string;
  userAbbr: string;
  clusterList: any = [];
  selectedCluster: any;
  isClusterAdmin: boolean = false;
  isBusinessUser: boolean = false;

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
    private authService: AuthService,
    private clusterService: ClusterService,
    private toast: MessageToastService,
    private menuToggle: MenuToggleService
  ) {}

  @Input()
  heading: string;
  // @Output()
  // toggleSidenav = new EventEmitter<void>();

  mode = "DARK MODE";
  options = {
    lang: "en",
    dark: false,
    settings: false,
    docked: false,
    boxed: false,
    opened: false
  };

  ngOnInit() {
    this.setUserDetails();
    this.getClusterList();
  }

  getClusterList() {
    let userDetails = JSON.parse(this.authService.getUser());
    console.log('user is '+userDetails['user_type']);
    console.log(userDetails);

    if (userDetails["user_type"] == "cluster_admin" || userDetails['user_type'] == "associate" || userDetails['user_type'] == "partner") {
      console.log("cluster details found out");
      console.log(userDetails["cluster_details"]);
      this.selectedCluster = userDetails["cluster_details"].identifier;
      this.clusterService.setGlobalCluster(userDetails["cluster_details"]);
      this.isClusterAdmin = true;
    } 
    else {
      this.clusterService.getClusterList().subscribe(val => {
        console.log("value from header cluster list");
        console.log(val["payload"].clusters);
        this.clusterList = val["payload"].clusters;
        if(this.clusterList[0]) {
          this.selectedCluster = this.clusterList[0].identifier;
          this.clusterService.setGlobalCluster(this.clusterList[0]);
          $("#header-cluster").val("");
          let element = document.getElementById("header-cluster-scroll");
          console.log("elemet found out for scrolling");
          console.log(element);
          element.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest"
          });
        } else {
          this.selectedCluster = "Create Cluster";
        }
        
        
      });
    }
    
    if(userDetails['user_type'] == 'amplus') {
      userDetails['user_roles'].forEach(val => {
        // console.log('current role value '+val);
        if(val == 'amplus_business') {
          // console.log('marking this as a business user');
          this.isBusinessUser = true;
        }
      });
    }
  }

  resetPassword() {
    let dialogRef = this.dialog.open(ResetPasswordComponent);
    console.log("resetting");
  }

  setUserDetails() {
    this.userName = JSON.parse(this.authService.getUser()).user_details.name;
    this.userAbbr = "";
    this.userName.split(" ").forEach(word => {
      if (word) {
        this.userAbbr += word[0];
      }
    });
  }

  signout() {
    let dialogRef = this.dialog.open(SignOutComponent);
  }

  // edit(cluster) {
  //   console.log('edit button clicked');
  //   console.log(cluster);
  //   // this.selectedCluster = cluster.identifier;
  // }

  addCluster() {
    console.log("adding cluster ");
    console.log($("#header-cluster").val());
    let data = { identifier: $("#header-cluster").val() };

    if (data.identifier != "") {
      this.clusterService.saveClusterData(data).subscribe(val => {
        if (val && val["status"]) {
          this.toast.success("CLUSTER ADDED SUCCESSFULLY");
          this.getClusterList();
        } else {
          this.toast.error(val["message"].toUpperCase());
        }
      });
    } else {
      this.toast.error('PLEASE ENTER A CLUSTER NAME');
    }
  }

  selectCluster(cluster) {
    console.log("selected cluster clicked");
    console.log(cluster);
    this.selectedCluster = cluster.identifier;
    this.clusterService.setGlobalCluster(cluster);
  }

  deleteCluster(cluster) {
    console.log("delete button clicked");
    console.log(cluster);
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if (data == "success") {
        this.clusterService
          .deleteClusterData(cluster.uuid)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("CLUSTER DELETED SUCCESSFULLY");
              this.getClusterList();
            }
          });
      }
    });
  }

  toggleSidemenu() {
    this.menuToggle.toggleMenu();
  }
}
