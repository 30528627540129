import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { LoggerService } from "../../service/logger/logger.service";
import { MessageToastService } from "../../service/toast/message-toast.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-iot-device",
  templateUrl: "./iot-device.component.html",
  styleUrls: ["./iot-device.component.scss"]
})
export class IotDeviceComponent implements OnInit {
  @Input() device_data: any;
  @Input() dropdown_data: any;
  @Output() update: EventEmitter<any> = new EventEmitter();

  // isCollapsed: boolean = false;
  form: any;
  device_type: any;
  device_provider: any;
  device_id: number;
  lead_uuid: string;

  constructor(
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private loggerService: LoggerService,
    private toast: MessageToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.device_id = this.device_data.id;

    this.route.params.subscribe(val => {
      this.lead_uuid = val.id;
    });

    if (this.dropdown_data.device_type) {
      this.device_type = [];
      Object.keys(this.dropdown_data.device_type).forEach(val => {
        this.device_type.push({
          key: val,
          value: this.dropdown_data.device_type[val]
        });
      });
    }

    if (this.dropdown_data.device_provider) {
      this.device_provider = [];
      Object.keys(this.dropdown_data.device_provider).forEach(val => {
        this.device_provider.push({
          key: val,
          value: this.dropdown_data.device_provider[val]
        });
      });
    }

    console.log("showing data got");
    console.log(this.device_data);

    this.form = this.fb.group({
      device_type: [this.device_data.device_type, Validators.required],
      device_name: [this.device_data.device_name, Validators.required],
      device_identifier: [this.device_data.device_identifier],
      device_provider: this.device_data.device_provider,
      installed_on:
        this.device_data.installed_on != 0
          ? new Date(this.device_data.installed_on * 1000)
          : null,
      removed_on:
        this.device_data.removed_on != 0
          ? new Date(this.device_data.removed_on * 1000)
          : null,
      sum_factor: [this.device_data.sum_factor]
    });
  }

  ngAfterViewInit() {
    console.log('printing device form value');
    console.log(this.form);
  }

  update_device() {
    console.log("printing value of device form");

    let formData = {};

    Object.keys(this.form.value).forEach(key => {
      formData[key] = this.form.value[key];
    });

    formData["id"] = this.device_id;

    if (formData['installed_on'] && formData["installed_on"] != 0) {
      formData["installed_on"] = Math.floor(
        formData["installed_on"].getTime() / 1000
      );
    }
    else {
      formData['installed_on'] = 0;
    }

    formData['sum_factor'] = formData['sum_factor'] ? formData['sum_factor'] : 0;
    
    console.log("from date");
    console.log(formData["installed_on"]);

    if (formData['removed_on'] && formData["removed_on"] != 0) {
      formData["removed_on"] = Math.floor(
        formData["removed_on"].getTime() / 1000
      );
    }
    else {
      formData['removed_on'] = 0;
    }

    console.log(formData);
    this.loggerService.updateDevice(formData, this.lead_uuid).subscribe(res => {
      if (res && res["status"]) {
        this.toast.success("SUCCESSFULLY UPDATED THE DEVICE");
        this.update.emit(true);
      }
    });
  }

  undo() {
    this.form.patchValue({
      device_type: this.device_data.device_type,
      device_name: this.device_data.device_name,
      device_provider: this.device_data.device_provider,
      device_identifier: this.device_data.device_identifier,
      sum_factor: this.device_data.sum_factor,
      installed_on:
        this.device_data.installed_on != 0
          ? new Date(this.device_data.installed_on * 1000)
          : 0,
      removed_on:
        this.device_data.removed_on != 0
          ? new Date(this.device_data.removed_on * 1000)
          : 0
    });
  }
}
