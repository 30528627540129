import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageToastService } from 'src/app/common/service/toast/message-toast.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { AddPropertyComponent } from 'src/app/common/component/add-property/add-property.component';
import { LeadService } from 'src/app/common/service/lead/lead.service';
import { AddClusterComponent } from 'src/app/common/component/add-cluster/add-cluster.component';
import { MenuToggleService } from 'src/app/common/service/menu-toggle/menu-toggle.service';
import { AuthService } from 'src/app/common/service/auth-service/auth.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CreateActivityLogComponent } from 'src/app/common/component/create-activity-log/create-activity-log.component';
import { SetReminderComponent } from 'src/app/common/component/set-reminder/set-reminder.component';
import { ActivityLogListComponent } from '../activity-log-list/activity-log-list.component';
import { environment } from 'src/environments/environment';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { LoaderService } from 'src/app/common/service/loader/loader.service';

const colMap = {
  Name: 'lead__first_name',
  'Phone Number': 'lead__phone_number_1',
  Address: 'address',
  Identifier: 'identifier',
  City: 'city',
  Source: 'lead__lead_source',
  'Lead Status': 'lms_status'
};

@Component({
  selector: 'app-property-cluster-assignment',
  templateUrl: './property-cluster-assignment.component.html',
  styleUrls: ['./property-cluster-assignment.component.scss']
})
export class PropertyClusterAssignmentComponent implements OnInit {
  rows = [];
  selected = [];
  selectAll = false;
  count = 0;
  offset = 0;
  limit: number;
  leadList = [];
  sortData: any = {};
  lmsStatusList: any = [];
  executiveList: any = [];
  pageNum = 0;
  pageCount = 0;
  formData: any = {};
  form: any;
  isBusinessUser = false;
  radio_selected = 'all';
  network_calls = 0;
  serverUrl: string = environment.serverURl;
  uploadUrl: string = 'v1/property/new/leads/fb';

  uploader: FileUploader = new FileUploader({
    url: this.serverUrl + this.uploadUrl,
    isHTML5: true,
    method: 'POST',
    itemAlias: 'file',
    authTokenHeader: 'authorization',
    authToken: this.authService.getAccessToken(),
    maxFileSize: 10 * 1024 * 1024
  });

  // this will store data of filter, sort and page change
  currentState = { page: 1, column_name: '', sort_order: '', filter: 'all' };

  @ViewChild('sidenav', { static: true }) sidenavBar: MatSidenav;
  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  constructor(
    private dialog: MatDialog,
    private toast: MessageToastService,
    private leadService: LeadService,
    private menuToggle: MenuToggleService,
    private fb: FormBuilder,
    private authService: AuthService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.getPropertyDetails(this.currentState);
    this.getFilterData();

    let user = this.authService.getUser();

    this.isBusinessUser =
      JSON.parse(user)['user_roles'].indexOf('amplus_business') >= 0
        ? true
        : false;

    this.form = this.fb.group({
      text: '',
      lms_status: '',
      executive_id: ''
    });

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    });

    this.menuToggle.toggleMenu(false);

    this.uploader.onWhenAddingFileFailed = (item, filter, options) =>
      this.onWhenAddingFileFailed(item, filter, options);

    this.uploader.onAfterAddingFile = file => {
      file.withCredentials = false;
      this.loaderService.show();
      this.network_calls++;
      console.log('showing the input file size');
      console.log(file.file.size);
      if (file.file.size < 10485760) {
        file.upload();
      }
    };

    this.uploader.onCompleteItem = async (val) => {
      console.log(this.uploader.queue);
      this.network_calls--;
      if (this.network_calls == 0) {
        this.loaderService.hide();
      }
    };

    this.uploader.onSuccessItem = (item, response, status, header) => this.manageResponse(item, response, status, header);
    this.uploader.onErrorItem = (item, response, status, header) => this.manageResponse(item, response, status, header);
  }

  manageResponse(item, response, status, header) {
    let data = JSON.parse(response);
    if(data && data['status']) {
      this.toast.success('FILE UPLOADED SUCCESSFULLY');
      this.getPropertyDetails(this.currentState);
    }
    else {
      this.toast.error(data.message.toUpperCase());
    }
  }

  onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any) {
    switch (filter.name) {
      case 'fileSize':
        console.log('file with the error');
        console.log(item);
        this.toast.error(
          'ERROR UPLOADING -' +
            this.cutName(item.name) +
            ' IS GREATER THAN 10MB'
        );
        break;
      case 'mimeType':
        break;
      default:
    }
  }

  cutName(name) {
    return name.substring(0, 6) + '....';
  }

  getPropertyDetails(data) {
    this.leadService.getNoClusterProperty(data).subscribe((result: any) => {
      if (result && result.status) {
        this.selectAll = false;
        console.log('result got from payload');
        console.log(result);
        this.pageCount = result['payload'].data.length;
        this.pageNum = result['payload'].current_page;
        result.payload.data.forEach(val => {
          if (val && val.lead) {
            val['full_name'] =
              (val.lead.first_name && val.lead.first_name != ''
                ? ' ' + val.lead.first_name
                : '') +
              (val.lead.last_name && val.lead.last_name != ''
                ? ' ' + val.lead.last_name
                : '');

            if (val['executive'].name) {
              val['executive'].name =
                val['executive'].name && val['executive'].name != ''
                  ? val['executive'].name
                  : '';
            } else {
              val['executive'] = { uuid: '', name: '' };
            }

            val['lms_status'] =
              val['lms_status'] && val['lms_status'] != ''
                ? val['lms_status']
                : '';

            val['phone_number'] =
              val.lead.phone_number_1 +
              (val.lead.phone_number2 ? ' , ' + val.lead.phone_number2 : '');
          }
        });
        this.leadList = [...result.payload.data];
        this.limit = result.payload.data_per_page;
        this.count = result.payload.count;
        this.offset = data['page'] - 1;
        this.sidenavBar.close();
      }
    });
  }

  onPage(event) {
    this.currentState['page'] = event.offset + 1;
    this.offset = event.offset + 1;
    this.getPropertyDetails(this.currentState);
    this.selected = [];
  }

  onSort(event) {
    console.log('sorting event');
    console.log(event);

    if (Object.keys(colMap).indexOf(event['column'].name) < 0) {
      return;
    }

    this.offset = 0;
    this.currentState['page'] = 1;
    this.currentState['column_name'] = colMap[event['column'].name];
    this.currentState['sort_order'] = event['newValue'];

    this.getPropertyDetails(this.currentState);
  }

  rowClick(event) {
    if (
      event.type == 'click' &&
      event.cellIndex != 0 &&
      (event.cellIndex != 6 || !this.isBusinessUser) &&
      (event.cellIndex != 7 || !this.isBusinessUser)
    ) {
      this.updateModal([event.row], false);
    }
  }

  updateModal(body, multi) {
    let dialogRef = this.dialog.open(AddClusterComponent, {
      data: { data: body, multi: multi }
    });

    dialogRef.componentInstance.status.subscribe(data => {
      if (data == 'success' || data == 'success_match') {
        console.log('current page num ' + this.pageNum);
        this.currentState['page'] =
          multi &&
          this.selected.length >= this.pageCount &&
          this.currentState['page'] > 1
            ? this.currentState['page'] - 1
            : this.currentState['page'];

        this.getPropertyDetails(this.currentState);
        this.selected = [];
      }
    });
  }

  editProperty(row) {
    this.updateModal([row], false);
  }

  viewActivityLog(row) {
    let dialogRef = this.dialog.open(ActivityLogListComponent, {
      data: row
    });
  }

  createActivityLog(row) {
    let dialogRef = this.dialog.open(CreateActivityLogComponent, {
      data: row
    });
  }

  setReminder(row) {
    let dialogRef = this.dialog.open(SetReminderComponent, {
      data: row
    });
  }

  getFilterData() {
    this.leadService.getNoClusterFilterData().subscribe(val => {
      this.lmsStatusList = [];
      this.lmsStatusList.push({ uuid: '', value: 'Select' });
      val['payload'].lms_status.forEach(status => {
        this.lmsStatusList.push({ uuid: status, value: status });
      });

      this.executiveList = [];
      this.executiveList.push({ uuid: '', name: 'Select' });
      this.executiveList.push(...val['payload'].executives);

      console.log('current lists');
      console.log(this.lmsStatusList);
      console.log(this.executiveList);

      this.clearForm();
    });
  }

  clearForm() {
    this.resetForm();
    let newState = {
      page: this.currentState.page,
      column_name: this.currentState.column_name,
      sort_order: this.currentState.sort_order,
      filter: this.currentState.filter
    };
    this.currentState = newState;
  }

  resetForm() {
    this.form.patchValue({
      text: '',
      lms_status: '',
      executive_id: ''
    });
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  assignCluster() {
    console.log('assigning clusters ' + this.selected.length);
    if (this.selected.length > 0) {
      this.updateModal(this.selected, true);
    } else {
      this.toast.error('PLEASE SELECT A LEAD');
    }
  }

  downloadList() {
    console.log('request for downloading list');
    let downloadState = JSON.parse(JSON.stringify(this.currentState));
    delete downloadState['page'];
    console.log('current angular download object');
    console.log(downloadState);
    downloadState['download'] = 1;
    this.leadService.downloadPropertyList(downloadState);
  }

  submit() {
    if (this.form.valid) {
      Object.keys(this.form.value).forEach(val => {
        console.log(val);
        if (this.form.value[val] != '' && this.form.value[val] != null) {
          this.currentState[val] = this.form.value[val];
        } else {
          delete this.currentState[val];
        }
      });
    }

    this.clearState();
    this.removeSortHeaders();
    this.getPropertyDetails(this.currentState);
    this.sidenavBar.close();
  }

  // clear sorting, and page data
  clearState() {
    this.currentState['page'] = 1;
    this.currentState['sort_order'] = '';
    this.currentState['column_name'] = '';
  }

  executiveChange(uuid, row) {
    console.log('executive change');
    console.log(row);
    console.log(uuid);
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      lead_uuid: row.lead.uuid,
      executive_uuid: uuid
    };
    this.leadService.updateExecutive(data).subscribe(val => {
      if (val && val['status']) {
        this.getPropertyDetails(this.currentState);
        this.toast.success('LEAD UPDATED SUCCESSFULLY');
      } else {
        this.toast.error('OPERATION FAILED');
      }
    });
  }

  radioChange(event) {
    console.log('current button selected');
    console.log(event);
    this.currentState['filter'] = event.value;
    this.radio_selected = this.currentState['filter'];
    this.clearState();
    this.removeSortHeaders();
    this.getPropertyDetails(this.currentState);
  }

  lmsStatusChange(value, row) {
    console.log('status change');
    let data = {
      lead_property_uuid: row.uuid ? row.uuid : null,
      lead_uuid: row.lead.uuid,
      lms_status: value
    };
    console.log(data);
    this.leadService.updateLmsStatus(data).subscribe(val => {
      if (val && val['status']) {
        this.getPropertyDetails(this.currentState);
        this.toast.success('LEAD UPDATED SUCCESSFULLY');
      } else {
        this.toast.error('OPERATION FAILED');
      }
    });
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
  }

  selectCheckBoxes() {
    this.selectAll = !this.selectAll;

    if (this.selectAll === false) {
      this.selected = [];
    } else {
      this.leadList.forEach(row => {
        if (row.uuid) {
          this.selected.push(row);
        }
      });
    }

    this.leadList = [...this.leadList];
  }

  removeSortHeaders() {
    this.table.sorts = [];
  }

  isDisabled(row) {
    if (row.uuid) {
      return false;
    }
    return true;
  }
}
