import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { ActivatedRoute } from "@angular/router";
import { LeadService } from "src/app/common/service/lead/lead.service";
import { CustomerAssignmentComponent } from "src/app/common/customer-assignment/customer-assignment.component";

declare var $: any;

const colMap = {
  "Name": "first_name",
  "Country Code": "country_code",
  "Phone Number": "phone_number_1",
  "Email": "email",
  "Source": "lead_source",
};

@Component({
  selector: "app-lead-no-property",
  templateUrl: "./lead-no-property.component.html",
  styleUrls: ["./lead-no-property.component.scss"]
})
export class LeadNoPropertyComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit: number;
  leadList = [];
  listSize: number;
  form: FormGroup;
  sortData: any;
  pageNum: number;

  constructor(
    private fb: FormBuilder,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private leadService: LeadService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      identifier: ["", Validators.required]
    });
    this.getLeadDetails({ page: 1 });
    console.log(this.route.snapshot);
  }

  getLeadDetails(data) {
    this.leadService.getNoPropertyLeads(data).subscribe((result: any) => {
      if (result && result.status) {
        result.payload.data.forEach(val => {
          val["full_name"] =
            val.first_name + (val.last_name != "" ? " " + val.last_name : "");
          val["phone_number"] = val
            ? val.phone_number_1 +
              (val.phone_number_2 ? " , " + val.phone_number_2 : "")
            : "";
        });
        console.log("lead list found out");
        console.log(result.payload.data);
        this.leadList = [...result.payload.data];
        this.limit = result.payload.data_per_page;
        this.count = result.payload.count;
        this.offset = data["page"] - 1;
      }
    });
  }

  onPage(event) {
    this.pageNum = event.offset + 1;
    let formData = {};
    formData["page"] = event.offset + 1;
    if (this.sortData && this.sortData["column_name"]) {
      formData["column_name"] = this.sortData["column_name"];
    }
    if (this.sortData && this.sortData["sort_order"]) {
      formData["sort_order"] = this.sortData["sort_order"];
    }
    console.log("final sort data sending to API");
    console.log(formData);
    this.getLeadDetails(formData);
  }

  onSort(event) {
    console.log("sort event created");
    console.log(event);
    this.sortData = {
      column_name: colMap[event["column"].name],
      sort_order: event["newValue"]
    };
    this.sortData["page"] = 1;
    this.getLeadDetails(this.sortData);
    this.offset = 0;
  }

  onActivate(event) {
    // if (event.type == "click" && event.cellIndex != 0) {
    //   console.log("clicked on row");
    //   console.log(event.row);
    //   let dialogRef = this.dialog.open(CustomerAssignmentComponent, {
    //     data: {row: event.row}
    //   });

    //   dialogRef.componentInstance.status.subscribe(data => {
    //     if(data == 'success') {
    //       this.onPage({offset: 0});
    //     }
    //   })
    // }
  }
}
