import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuToggleService {
  menuToggle = new BehaviorSubject(null);
  state = true;

  constructor() { }

  // false - menu close, true - menu open
  toggleMenu(mode = !this.state) {
    this.state = mode;
    this.menuToggle.next(this.state);
  }
}
