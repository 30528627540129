import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";
import { InputComponent } from "../input/input.component";
import { ButtonComponent } from "../button/button.component";
import { SelectComponent } from "../select/select.component";
import { DateComponent } from "../date/date.component";
import { RadiobuttonComponent } from "../radiobutton/radiobutton.component";
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { TextAreaComponent } from "../text-area/text-area.component";
import { FileUploadComponent } from "../file-upload/file-upload.component";
import { MapComponent } from "../map/map.component";
import { DateTimeComponent } from "../date-time/date-time.component";
import { GeoPointComponent } from "../geo-point/geo-point.component";
import { FloatComponent } from "../float/float.component";
import { IntegerComponent } from "../integer/integer.component";

const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  date: DateComponent,
  dateTime: DateTimeComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  textArea: TextAreaComponent,
  file: FileUploadComponent,
  map: MapComponent,
  geoPoint: GeoPointComponent,
  float: FloatComponent,
  integer: IntegerComponent
};

const componentGroup = {
  text: "input",
  text_area: "textArea",
  integer_and_unit: "input",
  integer: "integer",
  map: "map",
  dropdown_partner_sales: "select",
  dropdown_partner_sales_2: "select",
  dropdown_partner_sales_3: "select",
  dropdown_partner_sales_4: "select",
  dropdown_partner_epc: "select",
  dropdown_generic: "select",
  date: "date",
  datetime: "dateTime",
  file: "file",
  geo_point: "geoPoint",
  float: "float"
};

@Directive({
  selector: "[dynamicField]"
})
export class DynamicFieldDirective implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  @Input() access: string;
  @Input() validation: any;

  componentRef: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}
  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[componentGroup[this.field.type]]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
    this.componentRef.instance.access = this.access;
    this.componentRef.instance.validation = this.validation;
  }
}
