import { Component, OnInit, Inject, EventEmitter, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
import { LinkService } from "../../service/link/link.service";

@Component({
  selector: "app-edit-partner-link",
  templateUrl: "./edit-partner-link.component.html",
  styleUrls: ["./edit-partner-link.component.scss"]
})
export class EditPartnerLinkComponent implements OnInit {
  offset: Number = 0;
  fromPartnerName: String;

  @Output() status: EventEmitter<any> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPartnerLinkComponent>,
    private dialog: MatDialog,
    private linkService: LinkService
  ) {}

  ngOnInit() {
    console.log("data input got");
    console.log(this.data);
    this.fromPartnerName = this.data.current_partner;
  }

  delete(row) {
    console.log("deleting in modal");
    console.log(row);

    let dialogRef = this.dialog.open(DeleteModalComponent);
    dialogRef.componentInstance.option.subscribe(data => {
      if (data == "success") {
        let queryParams = {
          partner_from_id: this.fromPartnerName["uuid"],
          partner_to_id: row.uuid
        };
        this.linkService.deletePartnerLink(queryParams).subscribe(result => {
          this.status.emit("success");
          this.cancel();
        });
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
