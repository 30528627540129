import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { LinkService } from "src/app/common/service/link/link.service";
import { resolve } from "url";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MatDialog } from "@angular/material/dialog";
import { EditPartnerLinkComponent } from "src/app/common/component/edit-partner-link/edit-partner-link.component";

@Component({
  selector: "app-partner-link",
  templateUrl: "./partner-link.component.html",
  styleUrls: ["./partner-link.component.scss"]
})
export class PartnerLinkComponent implements OnInit {
  filterForm: any;
  clusters = [];
  form: any;
  selectedPartner: String = "";
  partnerList: any = [];
  linkedPartnerList: any = [];
  offset: Number = 0;
  isCollapsed = false;

  constructor(
    private fb: FormBuilder,
    private linkService: LinkService,
    private toast: MessageToastService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      partner_from_id: ["", Validators.required],
      partner_to_ids: ["", Validators.required]
    });

    // this.getPartnerList();

    // setTimeout(()=>{this.getLinkedPartnerList();},200);
    

    this.form.get("partner_from_id").valueChanges.subscribe(val => {
      console.log("value selected");
      console.log(val);
      this.selectedPartner = val;
    });
  }

  // getPartnerList() {
  //   this.linkService.getPartnerList().subscribe(val => {
  //     this.partnerList = [];
  //     this.partnerList.push({ uuid: "", name: "Select a partner" });
  //     this.partnerList.push(...val["payload"]);
  //   });
  // }

  // getLinkedPartnerList() {
  //   this.linkService.getLinkedPartnerList().subscribe(val => {
  //     console.log("list got from API");
  //     console.log(val);
  //     console.log(val["payload"]);
  //     if (Object.keys(val["payload"]).length != 0 && val['payload']!=null) {
  //       val["payload"].forEach(res => {
  //         res["linkedPartners"] = "";
  //         res.next_partners.forEach((val, idx) => {
  //           if (idx != 0) {
  //             res["linkedPartners"] += ", " + val.name;
  //           } else {
  //             res["linkedPartners"] = val.name;
  //           }
  //         });
  //       });
  //     } else {
  //       val['payload'] = [];
  //     }

  //     this.linkedPartnerList = val["payload"];
  //   });
  // }

  getAccess(data) {
    let option_text: string;
    if (data.phone_number) {
      option_text = data.name + " (" + <string>data.phone_number + ")";
    } else {
      option_text = data.name;
    }
    return option_text;
  }

  reset() {
    this.form.reset();
    this.form.get("partner_from_id").setValue("");
  }

  // deletePartnerLink(row) {
  //   console.log("deleting partner link");
  //   console.log(row);

  //   let dialogRef = this.dialog.open(EditPartnerLinkComponent, {
  //     data: row
  //   });

  //   dialogRef.componentInstance.status.subscribe(data => {
  //     if (data == "success") {
  //       this.getLinkedPartnerList();
  //       this.reset();
  //     }
  //   });
  // }

  // submit() {
  //   console.log("submitting form data");
  //   console.log(this.form.value);
  //   this.linkService.createPartnerLink(this.form.value).subscribe(res => {
  //     if (res && res["status"]) {
  //       this.toast.success("PARTNER LINKED SUCCESSFULLY");
  //       this.reset();
  //       this.getLinkedPartnerList();
  //     } else {
  //       this.toast.error("OPERATION FAILED");
  //     }
  //   });
  // }

  onPage(event) {}
}
