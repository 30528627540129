import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BaseService } from "../base.service";
import { environment } from "src/environments/environment";
import { MessageToastService } from "../toast/message-toast.service";

@Injectable({
  providedIn: "root"
})
export class AccountService {
  accountURL = "v1/account/amplus?";
  getAccountListURL = "v1/account/amplus/list";
  partnerAccountURL = "v1/account/partner";
  partnerAccountListURL = "v1/account/partner/list";
  propertyDetailsURL = 'v2/account/customer';
  constructor(
    private http: HttpClient,
    private messageService: MessageToastService
  ) {
    // super(http, " ", messageService);
    console.log("obj created");
  }

  saveUserAccount(data) {
    return this.http.post(environment.serverURl + this.accountURL, data);
  }

  updateUserAccount(data) {
    return this.http.put(environment.serverURl + this.accountURL, data);
  }

  deleteUserAccount(uuid) {
    return this.http.delete(
      environment.serverURl + this.accountURL + "uuid=" + uuid
    );
  }

  getAccountList() {
    return this.http.get(environment.serverURl + this.getAccountListURL);
  }

  savePartnerUserAccount(data) {
    return this.http.post(environment.serverURl + this.partnerAccountURL, data);
  }

  updatePartnerUserAccount(data) {
    return this.http.put(environment.serverURl + this.partnerAccountURL, data);
  }

  deletePartnerUserAccount(data) {
    return this.http.delete(environment.serverURl + this.partnerAccountURL, {
      params: data
    });
  }

  getPartnerAccountList(data) {
    return this.http.get(environment.serverURl + this.partnerAccountListURL, {
      params: data
    });
  }

  getPropertyDetails(data) {
    console.log("==================================");
    console.log(data);
    return this.http.get(environment.serverURl + this.propertyDetailsURL, {
      params: data
    });
  }
}
