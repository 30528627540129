export const environment = {
  production: false,
  serverURl: "https://devapi-astral.amplussolar.com/",
  firebase: {
    apiKey: "AIzaSyAuAvBWeOp8pWC9M405zs87-PNmKxe75T8",
    authDomain: "homescape-7329a.firebaseapp.com",
    databaseURL: "https://homescape-7329a.firebaseio.com",
    projectId: "homescape-7329a",
    storageBucket: "homescape-7329a.appspot.com",
    messagingSenderId: "550269142876",
    appId: "1:550269142876:web:0342221138e8bb145d6399",
    measurementId: "G-8F8GCBLEH9"
  }
};
