import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "src/app/common/service/account/account.service";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { MenuToggleService } from "src/app/common/service/menu-toggle/menu-toggle.service";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"]
})
export class UserManagementComponent implements OnInit {
  rows = [];
  count = 0;
  offset = 0;
  limit = 10;
  selectedCities1 = [];
  isEditMode = false;
  userList = [];
  isCollapsed = false;
  userListDetails = [];
  form: FormGroup;
  formValid: boolean = false;
  sidenavText: String = 'CREATE USER';

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private toast: MessageToastService,
    private dialog: MatDialog,
    private menuToggle: MenuToggleService
  ) {
    console.log(accountService);
  }

  ngOnInit() {
    this.form = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      is_analytic: [false],
      is_finance: [false],
      is_business: [false],
      is_technical: [false],
      is_admin: [false],
      is_executive: [false],
    });
    this.getUserDetails();

    this.form.valueChanges.subscribe(() => {
      this.formValid = this.form.valid;
    });

    this.menuToggle.menuToggle.subscribe(val => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, (300));
    });
  }

  submit() {
    if (this.form.valid) {
      if (this.isEditMode) {
        this.accountService
          .updateUserAccount(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("USER UPDATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.reset();
              this.sidenavText = 'CREATE USER';
              this.getUserDetails();
            } else {
              this.toast.error("ERROR OCCURED");
            }
          });
      } else {
        this.accountService
          .saveUserAccount(this.form.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toast.success("USER CREATED SUCCESSFULLY");
              this.sidenavBar.toggle();
              this.reset();
              this.sidenavText = 'CREATE USER';
              this.getUserDetails();
            } else {
              this.toast.error("ERROR OCCURED");
            }
          });
      }
    }
  }

  editAccount(row) {
    this.sidenavBar.toggle();
    this.reset();
    this.sidenavText = 'EDIT USER';
    this.form.patchValue({
      uuid: row.uuid,
      name: row.name,
      email: row.email,
      // password: row.password,
      is_analytic: row.is_analytic,
      is_finance: row.is_finance,
      is_business: row.is_business,
      is_technical: row.is_technical,
      is_executive: row.is_executive,
      is_admin: row.is_admin
    });
    // this.form.get("password").disable();
    this.form.get("email").disable();
    this.isEditMode = true;
  }

  deleteAccount(row) {
    let dialogRef = this.dialog.open(DeleteModalComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if(data == 'success') {
        this.accountService.deleteUserAccount(row.uuid).subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("USER DELETED SUCCESSFULLY");
            this.getUserDetails();
          }
        });
      }});
  }

  reset() {
    this.form.reset();
    this.sidenavText = 'CREATE USER';
    this.form.patchValue({
      is_analytic: false,
      is_finance: false,
      is_business: false,
      is_technical: false,
      is_executive: false,
      is_admin: false
    });
    // this.form.get("password").enable();
    this.form.get("email").enable();
    this.isEditMode = false;
  }

  getUserDetails() {
    this.accountService.getAccountList().subscribe((result: any) => {
      if (result && result.status) {
        result.payload.data.forEach(element => {
          let type = "";
          let nameAdded = false;
          if (element.is_analytic) {
            type += "Analytics";
            nameAdded = true;
          }
          if (element.is_business) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Business";

            nameAdded = true;
          }
          if (element.is_admin) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Admin";

            nameAdded = true;
          }
          if (element.is_finance) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Finance";

            nameAdded = true;
          }
          if (element.is_technical) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Technology";

            nameAdded = true;
          }
          if (element.is_executive) {
            if (nameAdded) {
              type += ", ";
            }
            type += "Executive";
          }

          element["type"] = type;
        });
        this.userList = result.payload.data;
        this.userListDetails = [...this.userList];
        this.offset = 0;
      }
    });
  }

  sidenavToggle() {
    this.sidenavBar.toggle();
    this.reset();
  }

  onPage(event) {
    // console.log(event);
    // const start = event.offset * this.limit;
    // const end = start + this.limit;
    // const rows = [];
    // this.offset = event.offset;
    // let index = 0;
    // for (let i = start; i < end; i++) {
    //   rows[index] = this.userListDetails[i];
    //   index++;
    // }
    // console.log(rows);

    // this.userList = rows;
    // this.userList = [...this.userList];
  }
}
