import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageToastService } from "src/app/common/service/toast/message-toast.service";
import { DepartmentService } from "src/app/common/service/department/department.service";
import { ClusterService } from "src/app/common/service/cluster/cluster.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { DeleteModalComponent } from "src/app/common/component/delete-modal/delete-modal.component";
import { EditDepartmentComponent } from "src/app/common/component/edit-department/edit-department/edit-department.component";
import { AssociateService } from "src/app/common/service/associate/associate.service";
import * as dragula from "dragula";
import { DragulaService } from "ng2-dragula";
import { Subscription } from "rxjs";
import { AddDepartmentComponent } from "src/app/common/component/add-department/add-department.component";
declare var $: any;

@Component({
  selector: "app-department-organisation",
  templateUrl: "./department-organisation.component.html",
  styleUrls: ["./department-organisation.component.scss"],
  viewProviders: [DragulaService]
})
export class DepartmentOrganisationComponent implements OnInit {
  departmentList = [1];
  associateForm: any;
  associateList: any = [];
  searchList: any = [];
  globalSearchQuery: String = '';
  departmentAssociateList: any = [];
  selectedCluster: any = null;
  subscriptionList = new Subscription();
  match = false;
  dragBin = false;
  isEditMode = false;
  editList = false;
  sidenavText = "ADD ASSOCIATE";

  @ViewChild("sidenav", { static: true }) sidenavBar: MatSidenav;
  constructor(
    private fb: FormBuilder,
    private toast: MessageToastService,
    private departmentService: DepartmentService,
    private clusterService: ClusterService,
    private associateService: AssociateService,
    private dialog: MatDialog,
    private dragularService: DragulaService
  ) {}

  ngOnInit() {
    this.associateForm = this.fb.group({
      uuid: [null],
      name: ["", Validators.required],
      email: ["", Validators.required],
      // password: ["", Validators.required],
      phone_number: ["", Validators.required],
      department_ids: [""],
      country_code: [91],
      cluster_id: [""]
    });

    this.subscriptionList.add(
      this.clusterService.globalCluster.subscribe(val => {
        if (val) {
          this.selectedCluster = val;
          // this.getDepartmentDetails(this.selectedCluster.uuid);
          $(".container-fluid").click();
          this.getAssociateList();
          this.getDepartmentAssociateList();
        }
      })
    );

    this.dragularService.destroy("task-group");
    this.dragularService.createGroup("task-group", {
      accepts: (el, target, source, sibling) => {
        console.log("printing drag event console");
        console.log(el);
        console.log(target);
        console.log(source);
        console.log(sibling);
        this.match = false;
        Array.from(target.childNodes).forEach(val => {
          if (
            val.childNodes[0] &&
            val.childNodes[0].childNodes[1] &&
            val.childNodes[0].childNodes[1].textContent &&
            val.childNodes[0].childNodes[1].textContent ==
              el.childNodes[0].childNodes[1].textContent
          ) {
            this.match = true;
          }
        });
        return (
          !this.match &&
          (source.getAttribute("id") == "associate-list" ||
            (source.getAttribute("id") != "associate-list" &&
              target.getAttribute("id") == "dragbin"))
        );
      },
      copy: (el, source) => {
        if (source.getAttribute("id") == "associate-list") {
          return true;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.subscriptionList.add(
      this.dragularService
        .drag("task-group")
        .subscribe(({ name, el, source }) => {
          if (source.getAttribute("id") != "associate-list") {
            this.dragBin = true;
          }
        })
    );

    this.subscriptionList.add(
      this.dragularService.dragend("task-group").subscribe(({ el }) => {
        this.dragBin = false;
      })
    );

    this.subscriptionList.add(
      this.dragularService
        .drop("task-group")
        .subscribe(({ name, el, target, source, sibling }) => {
          let associateUUID = el.childNodes[0].childNodes[2].textContent;
          let emailID = el.childNodes[0].childNodes[1].textContent.slice(7);
          let departmentUUID = "";
          if (target) {
            if (target.getAttribute("id") != "dragbin") {
              console.log("element moved");
              departmentUUID = target.getAttribute("id");
              this.associateService
                .addAssociateToDepartment(
                  associateUUID,
                  departmentUUID,
                  emailID,
                  this.selectedCluster.uuid
                )
                .subscribe(val => {
                  if (val && val["status"]) {
                    this.toast.success("SUCCESSFULLY ADDED ASSOCIATE");
                    this.getDepartmentAssociateList();
                    this.getAssociateList();
                  }
                });
            } else {
              console.log("element deleted");
              departmentUUID = source.getAttribute("id");
              this.associateService
                .deleteAssociateFromDepartment(associateUUID, departmentUUID)
                .subscribe(val => {
                  if (val && val["status"]) {
                    setTimeout(() => {}, 100);
                    this.toast.success("SUCCESSFULLY REMOVED ASSOCIATE");
                    this.getDepartmentAssociateList();
                    this.getAssociateList();
                  }
                });
            }
          }
        })
    );
  }

  editAssociate(associate) {
    console.log("clicked associate");
    console.log(associate);
    // let departmentList = [];
    // associate.departments.forEach(element => {
    //   departmentList.push(element.uuid);
    // });
    this.associateForm.patchValue({
      uuid: associate.uuid,
      name: associate.name,
      email: associate.email,
      // password: "password",
      phone_number: associate.phone_number,
      country_code: associate.country_code,
      department_ids: []
    });
    // this.associateForm.get("password").disable();
    this.isEditMode = true;
    this.sidenavBar.toggle();
  }

  getDepartmentAssociateList() {
    let data = { uuid: this.selectedCluster.uuid };
    this.associateService.getDepartmentAssociateList(data).subscribe(val => {
      if (val && val["status"]) {
        console.log("printing department associate list");
        console.log(val);
        this.departmentAssociateList = val["payload"];
      }
    });
  }

  getAssociateList() {
    console.log("current selected cluster " + this.selectedCluster.identifier);
    let data = { uuid: this.selectedCluster.uuid };
    this.associateService.getClusterAssociateList(data).subscribe(val => {
      if (val && val["status"]) {
        console.log("list found out");
        console.log(val);
        this.associateList = val["payload"].data;
        this.searchList = this.associateList;
        (<HTMLInputElement>document.getElementById('associate-search')).value = <string>this.globalSearchQuery;
        this.searchQuery(this.globalSearchQuery);
      }
    });
  }

  addDepartment() {
    console.log("adding department");
    let dialogRef = this.dialog.open(AddDepartmentComponent);

    dialogRef.componentInstance.option.subscribe(data => {
      if (data.status == "success") {
        console.log("dep name is not null");
        console.log(data.value);
        let formData = {
          uuid: null,
          name: data.value,
          cluster_id: this.selectedCluster.uuid
        };
        this.departmentService.saveDepartmentData(formData).subscribe(val => {
          if (val && val["status"]) {
            this.toast.success("DEPARTMENT ADDED SUCCESSFULLY");
            this.getDepartmentAssociateList();
          } else {
            this.toast.error("OPERATION FAILED");
          }
        });
      }
    });
  }

  getDepartmentDetails(cluster_id) {
    let queryParams = "cluster_id=" + cluster_id;

    this.departmentService
      .getDepartmentList(queryParams)
      .subscribe((result: any) => {
        if (result && result.status) {
          this.departmentList = result.payload.data;
          console.log("final department list");
          console.log(this.departmentList);
        }
      });
  }

  deleteDepartment(dep) {
    if (dep) {
      let dialogRef = this.dialog.open(DeleteModalComponent);
      dialogRef.componentInstance.option.subscribe(data => {
        if (data == "success") {
          this.departmentService
            .deleteDepartmentData(dep.uuid)
            .subscribe(val => {
              if (val && val["status"]) {
                this.toast.success("DEPARTMENT DELETED SUCCESSFULLY");
                this.getDepartmentAssociateList();
              }
            });
        }
      });
    }
  }

  editDepartment(dep) {
    console.log("editing department");
    console.log(dep);
    if (dep) {
      let dialogRef = this.dialog.open(EditDepartmentComponent, {
        data: { dep_name: dep.name }
      });
      dialogRef.componentInstance.dep_name.subscribe(val => {
        if (val) {
          let apiData = {
            uuid: dep.uuid,
            name: val,
            cluster_id: this.selectedCluster.uuid
          };
          console.log("received in parent " + val);
          this.departmentService
            .updateDepartmentData(apiData)
            .subscribe((result: any) => {
              if (result && result.status) {
                this.toast.success("DEPARTMENT UPDATED SUCCESSFULLY");
                this.getDepartmentAssociateList();
              }
            });
        }
      });
    }
  }

  toggleSidenav() {
    this.sidenavBar.toggle();
    this.reset();
    this.isEditMode = false;
  }

  secondaryOption() {
    if (this.isEditMode) {
      // delete associate
      console.log("deleting the associate");
      this.associateService
        .deleteAssociateData({
          uuid: this.associateForm.get("uuid").value,
          cluster_id: this.selectedCluster.uuid
        })
        .subscribe((result: any) => {
          if (result && result.status) {
            this.reset();
            this.toast.success("ASSOCIATE DELETED SUCCESSFULLY");
            this.getDepartmentAssociateList();
            this.getAssociateList();
            this.sidenavBar.close();
          }
        });
    } else {
      // clear form
      console.log("just clearing the form");
      this.reset();
    }
  }

  reset() {
    this.associateForm.reset();
    this.associateForm.get("country_code").setValue(91);
    // this.associateForm.get("password").enable();
  }

  submit() {
    if (this.associateForm.valid) {
      this.associateForm.get("cluster_id").setValue(this.selectedCluster.uuid);
      this.associateForm.get("department_ids").setValue([]);
      if (this.isEditMode) {
        this.associateService
          .updateAssociateData(this.associateForm.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toggleSidenav();
              this.toast.success(result["message"].toUpperCase());
              this.getAssociateList();
              this.getDepartmentAssociateList();
            }
          });
      } else {
        this.associateService
          .saveAssociateData(this.associateForm.value)
          .subscribe((result: any) => {
            if (result && result.status) {
              this.toggleSidenav();
              this.toast.success(result["message"].toUpperCase());
              this.getAssociateList();
              this.getDepartmentAssociateList();
            }
          });
      }
    }
  }

  searchQuery(value) {
    console.log("search changed");
    console.log(value);

    this.globalSearchQuery = value;

    this.searchList = this.associateList.filter(val =>
      val.name.toLowerCase().includes(value.toLowerCase())
    );
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
